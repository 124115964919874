import React from 'react'

const SnapchatIcon = () => {
  return (
        <svg fill="#000000" height="16px" width="16px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xmlSpace="preserve">
            <path
                d="M23,0H9C4,0,0,4,0,9v14c0,5,4,9,9,9h14c5,0,9-4,9-9V9C32,4,28,0,23,0z M28,22.5c-0.1,0.5-0.4,0.9-0.9,1.1 c-0.7,0.3-1.4,0.6-2.1,0.8c-0.1,0.9-0.7,1.5-1.5,1.5c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.1c-1.1,0-2.2,0.3-3.1,0.9 c-1.1,0.7-2.3,1.1-3.5,1.1c-0.6,0-1.3-0.1-2-0.3c-0.8-0.2-1.5-0.7-2.1-1c-0.8-0.5-1.6-0.7-2.6-0.7c-0.3,0-0.5,0-0.7,0 l-0.2,0 c-0.8-0.1-1.3-0.4-1.5-1.5c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.1L6,24c-0.4-0.1-0.8-0.3-1.1-0.5 C4.1,23.2,4,22.6,4,22.3 c0-0.3,0.1-0.8,0.8-1.2 c1.2-0.7,2.1-1.3,2.8-2.1 c0.6-0.7,1-1.4,1.2-2.1 c0,0,0,0,0,0 c-0.3-0.2-0.5-0.4-0.8-0.5 c-0.3-0.2-0.5-0.3-0.7-0.5 c-0.2-0.2-0.5-0.4-0.7-0.7 c-0.5-0.5-0.6-1.3-0.3-1.9 c0.3-0.7,1-1.1,1.7-1 c0.4,0.1,0.8,0.2,1.1,0.3 c0,0,0,0,0,0 c0-0.9,0-1.8,0-2.7 c0.2-2.3,1.5-4.1,3.6-5.1 C14.1,4.1,14.7,4,16,4 c0,0,0,0,0,0 c2.2,0,3.9,0.8,5.3,2.2 c0.9,1,1.4,2.1,1.6,3.5 c0.1,0.9,0.1,1.8,0.1,2.8 c0.1,0,0.1,0,0.2-0.1 c0.4-0.1,0.7-0.2,1.1-0.2 c0.7,0,1.4,0.4,1.6,1.1 c0.3,0.7,0.1,1.5-0.5,1.9 c-0.4,0.3-0.7,0.6-1.1,0.8 L24,16.3 c-0.1,0.1-0.2,0.2-0.4,0.3 c-0.1,0.1-0.2,0.1-0.3,0.2 c0,0-0.1,0.1-0.1,0.1 c0,0,0,0.1,0,0.1 c0.4,1.2,1.1,2.2,2.2,3 c0.3,0.3,0.7,0.5,1.1,0.7 c0.2,0.1,0.5,0.3,0.7,0.4 C28,21.6,28,22.1,28,22.5z"/>
        </svg>
  )
}

export default SnapchatIcon
