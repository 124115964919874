import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Mui
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { Avatar, InputLabel } from '@mui/material'

// Int
import { FormattedMessage } from 'react-intl'

// Style
import '../RecentEvents/RecentEvents.scss'

// Action
import { getMonth } from '../../../parser'

import {
  useGetAllEventsQuery,
  useGetEventTypesQuery,
  useGetSocialLinksQuery
} from '../../../redux/api/homePageApi/homePageApi'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  changeOpenReminderModal,
  onChangeDateFromAll,
  onChangeDateToAll,
  onChangeFilterEventIdAll,
  onChangeReminderId,
  onChangTotalEvents
} from '../../../redux/slices/eventsSlice/eventsSlice'
import { addEventId } from '../../../redux/slices/globalValue/globalValue'
import DatePicker, { registerLocale } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import TextField from '@mui/material/TextField'
import { formatDateString } from '../../../helper/formatDate/formatDate'
import Box from '@mui/material/Box'
import ReactPlayer from 'react-player'
import ShareIcon from '../../../images/icons/ShareIcon'
import CardBg from '../../../images/icons/CardBg'
import ReminderModal from '../../../components/ReminderModal'
import { shareUrlHelper, shareUrlIcon } from '../../../helper/shareUrlHelper/shareUrlHelper'
import ClipIcon from '../../../images/icons/ClipIcon'
import CopyLinkIcon from '../../../images/icons/CopyLinkIcon'
import { useMediaQuery } from 'react-responsive'

import { de } from 'date-fns/locale'

registerLocale('de', de)

const Events = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [loader, setLoader] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const convertToDateObject = (dateString) => {
    if (!dateString) return
    const [year, month, day] = dateString.split('-')
    return new Date(year, month - 1, day)
  }

  const onChangeStart = (dates) => {
    const start = formatDateString(dates)
    setStartDate(start)
  }

  const onChangeEnd = (dates) => {
    const end = formatDateString(dates)
    setEndDate(end)
  }

  const { offset, limit, eventId } = useSelector((state) => state.eventSliceReducer.filterAll)
  const { from, to } = useSelector((state) => state.eventSliceReducer.dateAll)
  const { isOpenReminderModal } = useSelector((state) => state.eventSliceReducer)
  const searchValue = useSelector((state) => state.eventSliceReducer.searchValue)

  const [activeSocialLinksId, setActiveSocialLinksId] = useState(null)
  const [isCopied, setIsCopied] = useState(false)

  const {
    data: filteredEvents = [],
    isLoading: loadingFilter,
    isFetching: fetchingFilter
  } = useGetAllEventsQuery({ eventId, offset, limit, searchValue, from, to }
    // {
    //   skip: !eventId
    // }
  )

  const {
    data: socialLinks = []
  } = useGetSocialLinksQuery()

  const {
    data: eventTypes = []
  } = useGetEventTypesQuery()

  useEffect(() => {
    if (window.location.pathname === '/') return
    dispatch(onChangTotalEvents(filteredEvents?.data?.meta?.total_count))
  }, [filteredEvents])

  const getDate = (date) => {
    return new Date(date).getDate()
  }

  const handleEventFilter = async (e) => {
    setLoader(true)
    const eventId = e.target.value

    if (eventId === 'all') {
      dispatch(onChangeFilterEventIdAll(null))
    } else {
      dispatch(onChangeFilterEventIdAll(eventId))
    }

    setLoader(false)
  }

  const handleRedirect = (data) => {
    if (data?.event_schedule === 'recent') {
      dispatch(addEventId({ eventId: data.id }))

      navigate(
        '/events/' +
                data.id +
                '/' +
                data.slug,
        {
          state: { id: data.id }
        }
      )
    } else {
      dispatch(onChangeReminderId(data.id))
      dispatch(changeOpenReminderModal(true))
    }
  }

  useEffect(() => {
    if (!startDate) return
    dispatch(onChangeDateFromAll(formatDateString(startDate)))
  }, [startDate])

  useEffect(() => {
    if (!endDate) return
    dispatch(onChangeDateToAll(formatDateString(endDate)))
  }, [endDate])

  const CustomInputFrom = useCallback(({ onClick }) => {
    return <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <TextField
                className="date-pick-input"
                type="text"
                // label='Date'
                label='Vom'
                fullWidth
                onClick={onClick}
                value={startDate ? formatDateString(startDate) : null}
            />
        </Box>
  }, [startDate])

  const CustomInputTo = useCallback(({ onClick }) => {
    return <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <TextField
                className="date-pick-input"
                type="text"
                label='Bis'
                fullWidth
                onClick={onClick}
                value={endDate ? formatDateString(endDate) : null}
            />
        </Box>
  }, [endDate])

  const recentEventsArr = filteredEvents?.data?.items

  return (
        <>
            <div className="recents-events-main">
                {isOpenReminderModal && <ReminderModal/>}
                <div className="recents-events-head">
                    <p className="title"><FormattedMessage id="recent-events"
                        // defaultMessage='Results'/>
                                                           defaultMessage='Ergebnisse'/>
                    </p>
                    <div className="recents-events-head-option">
                        <FormControl sx={{ width: '100%', maxWidth: !isTabletOrMobile ? '220px' : '100%' }}
                                     className="form-control-home-page">
                            {/* <InputLabel id="event-type-label">Event type</InputLabel> */}
                            <InputLabel id="event-type-label"><FormattedMessage id="filter"
                                                                                defaultMessage='Filter'/></InputLabel>
                            <Select
                                label={<FormattedMessage id="filter" defaultMessage='Filter'/>}
                                placeholder='Event type'
                                value={eventId || 'all'}
                                onChange={handleEventFilter}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {/* <MenuItem key={1} value='all'>All types</MenuItem> */}
                                {/* <MenuItem key={2} value={1}>DomMusik</MenuItem> */}
                                {/* <MenuItem key={3} value={2}>Gottesdienst</MenuItem> */}
                                {/* <MenuItem key={4} value={3}>crossPoint</MenuItem> */}

                                <MenuItem key={1} value='all'>Alle</MenuItem>
                                {!!eventTypes.length && eventTypes?.map(el => {
                                  return <MenuItem key={2} value={el.value}>{el.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <DatePicker
                            className='picker'
                            onChange={onChangeStart}
                            locale="de"
                            selected={convertToDateObject(startDate)}
                            customInput={<CustomInputFrom/>}
                        />
                        <DatePicker
                            className='picker'
                            onChange={onChangeEnd}
                            locale="de"
                            selected={convertToDateObject(endDate)}
                            customInput={<CustomInputTo/>}
                        />
                    </div>

                </div>
                {loader || fetchingFilter || loadingFilter
                  ? <CircularProgress className={'circularProgress'}/>

                  : <div className="recents-events-cards-main" style={{ paddingBottom: pathname !== '/' ? '165px' : '' }}>
                        {recentEventsArr.map((data, index) => {
                          return (
                                <div
                                    className="recents-events-card"
                                    key={data.id}
                                    onClick={() => navigate(
                                      '/events/' +
                                        data.id +
                                        '/' +
                                        data.slug,
                                      {
                                        state: { id: data.id }
                                      }
                                    )}
                                >
                                    {data?.image?.download_url && !data?.stream?.hls_url && !data?.stream?.embed_code &&
                                        <img
                                            onClick={() => handleRedirect(data)}
                                            className="recents-events-card-img"
                                            src={data.image?.download_url}
                                            alt="event-card"
                                        />

                                    }
                                    {data?.stream?.hls_url &&
                                        <ReactPlayer
                                            url={data?.stream?.hls_url}
                                            controls={true}
                                            width="100%"
                                            height="auto"
                                            style={{
                                              borderTopLeftRadius: '12px',
                                              borderTopRightRadius: '12px',
                                              overflow: 'hidden'
                                            }}
                                            playing={false}
                                        />
                                    }
                                    {data?.stream?.embed_code && !data?.stream?.hls_url &&
                                        <div className='iframe-container'>
                                            <iframe style={{
                                              borderTopLeftRadius: '12px',
                                              borderTopRightRadius: '12px',
                                              overflow: 'hidden'
                                            }} src={data?.stream?.embed_code}></iframe>
                                        </div>
                                    }
                                    {!data?.image?.download_url && !data?.stream?.hls_url && !data?.stream?.embed_code &&
                                        <div className="recents-events-card-img recents-events-card-img--bg">
                                            <CardBg/>
                                        </div>

                                    }
                                    <div className='card-description-wrap'>
                                        <div className="share-icon"
                                             onClick={event => event.stopPropagation()}
                                             onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                                             onMouseLeave={() => setActiveSocialLinksId(null)}
                                        >
                                            <ShareIcon/>
                                        </div>
                                        {activeSocialLinksId === data?.id &&
                                            <div className='social-links-container'
                                                 onClick={event => event.stopPropagation()}
                                                 onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                                                 onMouseLeave={() => setActiveSocialLinksId(null)}
                                            >
                                                <div
                                                    onClick={event => event.stopPropagation()}
                                                    onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                                                    onMouseLeave={() => setActiveSocialLinksId(null)}
                                                    className='social-links-wrap'>
                                                    {!!socialLinks?.length &&
                                                        <div className='social-links-wrap social-links-wrap--pt'>
                                                            <p key='copy-link' onClick={() => {
                                                              navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_HOST}events/${data.id}/${data.slug}`).then(() => {
                                                                setIsCopied(true)

                                                                setTimeout(() => {
                                                                  setIsCopied(false)
                                                                }, 2000)
                                                              })
                                                            }}
                                                               className='social-links-item social-links-item--download'>
                                                                <CopyLinkIcon/>
                                                                {/* {isCopied ? 'Copied' : 'Copy link'} */}
                                                                {isCopied ? 'Copied' : 'Link kopieren'}
                                                            </p>
                                                            {socialLinks.map((el, i) => {
                                                              const url = process.env.REACT_APP_BASE_HOST + 'events/' + data?.id + '/' + data?.slug

                                                              return <a href={shareUrlHelper(url)[`${el.label}`]}
                                                                          target="_blank" key={i}
                                                                          className='social-links-item'
                                                                          rel="noopener noreferrer">
                                                                    {shareUrlIcon()[`${el.label}`]}
                                                                    {el.label}
                                                                </a>
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {!!data?.attachments?.length &&
                                            <div className="share-icon share-icon--download"
                                            >
                                                <ClipIcon/>
                                            </div>
                                        }
                                        <div className="recents-event-card-detail">
                                            <div>
                                                <p className="day">{getDate(data.schedule_begin)}</p>
                                                <p className="month">{getMonth(data.schedule_begin)}</p>

                                                {data.schedule_begin?.substring(0, 10) !==
                                                data.schedule_end?.substring(0, 10) && data?.schedule_end
                                                  ? (
                                                        <>
                                                            {' '}
                                                            <span className="day-line"></span>
                                                            <p className="day">{getDate(data.schedule_end)}</p>
                                                            <p className="month">
                                                                {getMonth(data.schedule_end)}
                                                            </p>
                                                        </>
                                                    )
                                                  : (
                                                      ''
                                                    )}
                                            </div>
                                            <div>
                                                <div>
                                                    <p className="event-heading">{data.title}</p>
                                                    <p className="time">
                                                        {data.schedule_begin?.substring(11, 16)}
                                                        {data.schedule_end ? ' - ' : ''}
                                                        {data.schedule_end ? data.schedule_end?.substring(11, 16) : ''}
                                                    </p>
                                                    <div className="recents-event-speakers">
                                                        {/* ev.speakers.items.map((sp) => <>{sp.people?.full_name}</> */}
                                                        {data?.speakers?.map((speaker, index) => {
                                                          return (
                                                                <div key={speaker?.people?.id}>
                                                                    {index < 5 && (
                                                                        <div className="speakers-overlay-main"
                                                                             style={{ marginLeft: index > 0 ? '-10px' : '' }}>
                                                                            <img
                                                                                src={speaker.avatar.download_url}
                                                                                alt="speaker"
                                                                                // title={speaker.people.full_name}
                                                                                width="36px"
                                                                                height="36px"
                                                                                className="speaker-dp"
                                                                                // onMouseOver={(e) => setAnchorEl(e.currentTarget)}
                                                                                // onMouseLeave={(e) => setAnchorEl(null)}
                                                                            />
                                                                            <div className="speakers-overlay"
                                                                                 onClick={(event) => event.stopPropagation()}
                                                                                 style={{ top: pathname.includes('events/') ? '' : '35px' }}>
                                                                                <div className="speaker-title">
                                                                                    <div className="speaker-avatar">
                                                                                        <Avatar
                                                                                            sx={{ width: 36, height: 36 }}
                                                                                            src={speaker?.avatar?.download_url}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="speaker-name">
                                                                                        <p className="name">
                                                                                            {speaker?.full_name}
                                                                                        </p>
                                                                                        <p className="type">
                                                                                            {speaker?.title}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {speaker?.biography &&
                                                                                    <div className="speaker-bio">
                                                                                        {speaker?.biography}
                                                                                    </div>}
                                                                                <div className="speakers-links">
                                                                                    {speaker?.linkedin &&
                                                                                        <a href={speaker?.linkedin}
                                                                                           onClick={(event) => event.stopPropagation()}>
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 16 16"
                                                                                                fill="none">
                                                                                                <path fillRule="evenodd"
                                                                                                      clipRule="evenodd"
                                                                                                      d="M6.28567 5.9795H8.76167V7.21283C9.11834 6.5035 10.033 5.86616 11.407 5.86616C14.041 5.86616 14.6663 7.27816 14.6663 9.86883V14.6668H11.9997V10.4588C11.9997 8.9835 11.643 8.1515 10.735 8.1515C9.47568 8.1515 8.95234 9.04816 8.95234 10.4582V14.6668H6.28567V5.9795ZM1.71301 14.5535H4.37967V5.86616H1.71301V14.5535ZM4.76167 3.0335C4.76177 3.25701 4.71745 3.47832 4.63127 3.68455C4.54509 3.89079 4.41878 4.07784 4.25967 4.23483C3.93727 4.55525 3.50088 4.7346 3.04634 4.7335C2.5926 4.73319 2.15722 4.5543 1.83434 4.2355C1.67582 4.07797 1.54993 3.89071 1.4639 3.68445C1.37788 3.47819 1.33339 3.25698 1.33301 3.0335C1.33301 2.58216 1.51301 2.15016 1.83501 1.8315C2.1576 1.51227 2.59317 1.3333 3.04701 1.3335C3.50167 1.3335 3.93767 1.51283 4.25967 1.8315C4.58101 2.15016 4.76167 2.58216 4.76167 3.0335Z"
                                                                                                      fill="$color-header-text"/>
                                                                                            </svg>
                                                                                        </a>}

                                                                                    {speaker?.facebook &&
                                                                                        <a href={speaker?.facebook}
                                                                                           onClick={(event) => event.stopPropagation()}>
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="8"
                                                                                                height="16"
                                                                                                viewBox="0 0 8 16"
                                                                                                fill="none">
                                                                                                <g clip-path="url(#clip0_3186_16052)">
                                                                                                    <path
                                                                                                        d="M2.04221 16V8.49234H0V5.78922H2.04221V3.4804C2.04221 1.66611 3.24856 0 6.02823 0C7.15368 0 7.98589 0.10488 7.98589 0.10488L7.92032 2.62914C7.92032 2.62914 7.07159 2.6211 6.14543 2.6211C5.14303 2.6211 4.98244 3.07014 4.98244 3.81544V5.78922H8L7.8687 8.49234H4.98244V16H2.04221Z"
                                                                                                        fill="$color-header-text"/>
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath
                                                                                                        id="clip0_3186_16052">
                                                                                                        <rect width="8"
                                                                                                              height="16"
                                                                                                              fill="white"/>
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </a>}

                                                                                    {speaker?.twitter &&
                                                                                        <a href={speaker?.twitter}
                                                                                           onClick={(event) => event.stopPropagation()}>
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 16 16"
                                                                                                fill="none">
                                                                                                <path
                                                                                                    d="M15.7623 3.29122C15.2057 3.53789 14.6077 3.70455 13.979 3.77989C14.6277 3.39175 15.113 2.78087 15.3443 2.06122C14.7349 2.42321 14.0679 2.67802 13.3723 2.81456C12.9046 2.31513 12.285 1.98411 11.6099 1.87287C10.9347 1.76164 10.2417 1.87642 9.63849 2.19939C9.03525 2.52236 8.55552 3.03546 8.27377 3.65902C7.99202 4.28259 7.92402 4.98172 8.08033 5.64789C6.84546 5.58589 5.63742 5.26492 4.53462 4.70582C3.43181 4.14673 2.45889 3.362 1.679 2.40256C1.41233 2.86256 1.259 3.39589 1.259 3.96389C1.2587 4.47522 1.38462 4.97872 1.62559 5.42971C1.86655 5.8807 2.21511 6.26525 2.64033 6.54922C2.14718 6.53353 1.66492 6.40028 1.23367 6.16055V6.20055C1.23362 6.91771 1.48169 7.61281 1.93579 8.16788C2.38989 8.72296 3.02205 9.10384 3.725 9.24589C3.26752 9.3697 2.78789 9.38793 2.32233 9.29922C2.52066 9.9163 2.907 10.4559 3.42724 10.8425C3.94749 11.2291 4.57561 11.4433 5.22367 11.4552C4.12356 12.3188 2.76492 12.7873 1.36633 12.7852C1.11859 12.7853 0.871051 12.7708 0.625 12.7419C2.04465 13.6547 3.69723 14.1391 5.385 14.1372C11.0983 14.1372 14.2217 9.40522 14.2217 5.30122C14.2217 5.16789 14.2183 5.03322 14.2123 4.89989C14.8199 4.46054 15.3443 3.91649 15.761 3.29322L15.7623 3.29122Z"
                                                                                                    fill="$color-header-text"/>
                                                                                            </svg>
                                                                                        </a>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                          )
                                                        })}
                                                        {/* {data.speakers.data.items.length - 5 > 0 && ( */}
                                                        {/*    <div className="more-rounded-btn"> */}
                                                        {/*        <p>{data.speakers.data.items.length - 5} */}
                                                        {/*            <FormattedMessage */}
                                                        {/*                id="more" */}
                                                        {/*                defaultMessage="more"/> */}
                                                        {/*        </p> */}
                                                        {/*    </div> */}
                                                        {/* )} */}
                                                    </div>
                                                    <div className="event-desc">{data.hero_text}
                                                        <div className="event-desc-decoration"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {data?.attachments?.length === 1 && data?.event_schedule === 'recent' && */}
                                    {/*    <div className='lyrics-button'> */}
                                    {/*        <a key={data?.attachments[0]?.id} */}
                                    {/*           href={data?.attachments[0]?.meta?.download_url} */}
                                    {/*           className='social-links-item social-links-item--download'> */}
                                    {/*            <FileIcon/> */}
                                    {/*            {data?.attachments[0]?.title} */}
                                    {/*        </a> */}
                                    {/*    </div> */}
                                    {/* } */}
                                    {data?.event_schedule !== 'recent' &&
                                        <div className='lyrics-button-wrap' onClick={() => {
                                          handleRedirect(data)
                                          navigate(
                                            '/events/' +
                                                data.id +
                                                '/' +
                                                data.slug,
                                            {
                                              state: { id: data.id }
                                            }
                                          )
                                        }}>
                                            <div className='lyrics-button'
                                                 onClick={(event) => {
                                                   event.stopPropagation()
                                                   handleRedirect(data)
                                                   // }}>{data?.event_schedule === 'recent' ? 'Lyrics' : 'Set a reminder'}
                                                 }}>{data?.event_schedule === 'recent' ? 'Lyrics' : 'Erinnerung aktivieren'}
                                            </div>
                                        </div>
                                    }
                                </div>
                          )
                        })}
                    </div>
                }
            </div>
        </>
  )
}
export default Events
