import React, { useEffect } from 'react'
import './StartEventModal.scss'
import CloseIcon from '../../images/icons/CloseIcon'
import Box from '@mui/material/Box'
import { useGetEventDescQuery } from '../../redux/api/homePageApi/homePageApi'
import { useNavigate } from 'react-router-dom'

const StartEventModal = ({ id, setModalVisible }) => {
  const navigate = useNavigate()
  const { data: event } = useGetEventDescQuery(id)

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.classList.add('hide-scroll')

    return () => {
      body.classList.remove('hide-scroll')
    }
  }, [])

  return (
        <div className='modal-bg'>
            <div className='event-modal'>
                <Box className='event-modal__close-icon'
                     onClick={() => {
                       setModalVisible(false)
                     }}>
                    <CloseIcon/>
                </Box>
                <div>
                    <h1 className='event-modal__title'>
                        Veranstaltung : {event?.title} -- beginnt.
                    </h1>
                    <button className='event-modal__success-button'
                            onClick={() => {
                              navigate(
                                '/events/' +
                                    event?.id +
                                    '/' +
                                    event?.slug
                              )
                              setModalVisible(false)
                            }}>Zur Veranstaltung
                    </button>
                </div>
            </div>
        </div>
  )
}

export default StartEventModal
