import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

// src
import './Footer.scss'
// import GMAG from '../../images/sponsors/10.png'
// import THURCOM from '../../images/sponsors/09.png'
// import SGSW from '../../images/sponsors/01.png'
// import Scandola from '../../images/sponsors/12.png'
// import Vitronic from '../../images/sponsors/22.png'
// import Buehler from '../../images/sponsors/02.png'
// import Leica from '../../images/sponsors/03.png'
// import Bauwerk from '../../images/sponsors/04.png'
// import Migros from '../../images/sponsors/05.png'
// import PWC from '../../images/sponsors/06.png'
// import Sorec from '../../images/sponsors/08.png'
// import Thurgau from '../../images/sponsors/11.png'
// import Abraxas from '../../images/sponsors/13.png'
// import SchmidFehr from '../../images/sponsors/14.png'
// import Martel from '../../images/sponsors/15.png'
// import Hofweissbad from '../../images/sponsors/16.png'
// import HuberSuhner from '../../images/sponsors/17.png'
// import Leader from '../../images/sponsors/18.png'
// import Tagblatt from '../../images/sponsors/19.png'
// import TVO from '../../images/sponsors/20.png'
// import GRIPS from '../../images/sponsors/21.png'
// import AI from '../../images/sponsors/23.png'
// import SG from '../../images/sponsors/24.png'
// import SGE from '../../images/sponsors/25.png'
// import IHKSG from '../../images/sponsors/26.png'
// import IHKTG from '../../images/sponsors/27.png'
// import WRSG from '../../images/sponsors/28.png'
// import GEWERBESG from '../../images/sponsors/29.png'
// import FooterNewLogo from '../../images/icons/FooterNewLogo'
import YouTubeIcon from '../../images/icons/YouTubeIcon'
import InstagramIcon from '../../images/icons/InstagramIcon'
import FacebookIcon from '../../images/icons/FacebookIcon'

const Footer = () => {
  const location = useLocation()

  const events = location.pathname.split('/')[1]

  return (
        <div>
            {/* // fix it */}
            {events === 'events--' &&
                <>
                    {/* <div className="footer-page-logos"> */}
                    {/*    /!* <HeadLogo/> *!/ */}
                    {/*    <a href="https://gallusmedia.ch" target={'_blank'} rel="noreferrer"><img src={GMAG} */}
                    {/*                                                                             alt={'Gallus Media'}/></a> */}
                    {/*    <a href="https://www.thurcom.ch/" target={'_blank'} rel="noreferrer"><img src={THURCOM} */}
                    {/*                                                                              alt={'Thurcom'}/></a> */}
                    {/*    <a href="https://www.sgsw.ch/" target={'_blank'} rel="noreferrer"><img src={SGSW} */}
                    {/*                                                                           alt="SGSW"/></a> */}
                    {/*    <a href="https://www.scandola.ch/" target={'_blank'} rel="noreferrer"><img src={Scandola} */}
                    {/*                                                                               alt="Scandola"/></a> */}
                    {/*    <a href="https://www.vitronic.ch/" target={'_blank'} rel="noreferrer"><img src={Vitronic} */}
                    {/*                                                                               alt="Vitronic"/></a> */}
                    {/*    <a href="https://www.buhlergroup.ch/" target={'_blank'} rel="noreferrer"><img src={Buehler} */}
                    {/*                                                                                  alt="Buehler"/></a> */}
                    {/*    <a href="https://www.leica-geosystems.ch/" target={'_blank'} rel="noreferrer"><img src={Leica} */}
                    {/*                                                                                       alt="Leica"/></a> */}
                    {/*    <a href="https://www.bauwerk-parkett.com/" target={'_blank'} rel="noreferrer"><img src={Bauwerk} */}
                    {/*                                                                                       alt="Bauwerk"/></a> */}
                    {/*    <a href="https://www.migros.ch/" target={'_blank'} rel="noreferrer"><img src={Migros} */}
                    {/*                                                                             alt="Migros"/></a> */}
                    {/*    <a href="https://www.pwc.ch/" target={'_blank'} rel="noreferrer"><img src={PWC} alt="PWC"/></a> */}
                    {/*    <a href="https://www.sorec.ch/" target={'_blank'} rel="noreferrer"><img src={Sorec} */}
                    {/*                                                                            alt="Sorec"/></a> */}
                    {/*    <a href="https://www.thurgau.ch/" target={'_blank'} rel="noreferrer"><img src={Thurgau} */}
                    {/*                                                                              alt="Thurgau"/></a> */}
                    {/*    <a href="https://www.abraxas.ch/" target={'_blank'} rel="noreferrer"><img src={Abraxas} */}
                    {/*                                                                              alt="Abraxas"/></a> */}
                    {/*    <a href="https://www.schmid-fehr.ch/" target={'_blank'} rel="noreferrer"><img src={SchmidFehr} */}
                    {/*                                                                                  alt="SchmidFehr"/></a> */}
                    {/*    <a href="https://www.martel.ch/" target={'_blank'} rel="noreferrer"><img src={Martel} */}
                    {/*                                                                             alt="Martel"/></a> */}
                    {/*    <a href="https://www.hofweissbad.ch/" target={'_blank'} rel="noreferrer"><img src={Hofweissbad} */}
                    {/*                                                                                  alt="Hofweissbad"/></a> */}
                    {/*    <a href="https://www.hubersuhner.com/" target={'_blank'} rel="noreferrer"><img src={HuberSuhner} */}
                    {/*                                                                                   alt="HuberSuhner"/></a> */}
                    {/*    <a href="https://www.leaderdigital.ch/" target={'_blank'} rel="noreferrer"><img src={Leader} */}
                    {/*                                                                                    alt="Leader"/></a> */}
                    {/*    <a href="https://www.tagblatt.ch/" target={'_blank'} rel="noreferrer"><img src={Tagblatt} */}
                    {/*                                                                               alt="Tagblatt"/></a> */}
                    {/*    <a href="https://www.tvo-online.ch/" target={'_blank'} rel="noreferrer"><img src={TVO} */}
                    {/*                                                                                 alt="TVO"/></a> */}
                    {/*    <a href="https://www.grips.ch/" target={'_blank'} rel="noreferrer"><img src={GRIPS} */}
                    {/*                                                                            alt="GRIPS"/></a> */}
                    {/*    <a href="https://www.ai.ch/" target={'_blank'} rel="noreferrer"><img src={AI} alt="AI"/></a> */}
                    {/*    <a href="https://www.sg.ch/wirtschaft-arbeit.html" target={'_blank'} rel="noreferrer"><img */}
                    {/*        src={SG} alt="SG"/></a> */}
                    {/*    <a href="https://www.s-ge.com/" target={'_blank'} rel="noreferrer"><img src={SGE} */}
                    {/*                                                                            alt="SGE"/></a> */}
                    {/*    <a href="https://www.ihk.ch/" target={'_blank'} rel="noreferrer"><img src={IHKSG} alt="IHKSG"/></a> */}
                    {/*    <a href="https://www.ihk-thurgau.ch/" target={'_blank'} rel="noreferrer"><img src={IHKTG} */}
                    {/*                                                                                  alt="IHKTG"/></a> */}
                    {/*    <a href="https://www.wisg.ch/" target={'_blank'} rel="noreferrer"><img src={WRSG} */}
                    {/*                                                                           alt="WRSG"/></a> */}
                    {/*    <a href="https://www.gewerbesg.ch/" target={'_blank'} rel="noreferrer"><img src={GEWERBESG} */}
                    {/*                                                                                alt="GEWERBESG"/></a> */}

                    {/* </div> */}
                </>
            }
            <div className="footer-page-container">
                <div className="footer-page-links">
                    <div className="footer-page-links-wrap">
                        <div className='logo-with-address-wrap'>
                            {/* <FooterNewLogo/> */}

                            <div>
                                <p className='footer-page-links__item'>Evangelisch-reformierte Kirchgemeinde
St. Gallen C</p>
                                <p className='footer-page-links__item'>Kirche St. Laurenzen, Marktgasse 25, 9000 St. Gallen</p>
                                {/* <p className='footer-page-links__item'>CH-9001 St.Gallen</p> */}
                            </div>
                        </div>
                        <div>
                            <a href='tel:+41712226792' className='footer-page-links__item'>+41 71 222 67 92</a>
                            <a href='mailto:bea.kaenel@ref-sgc.ch'
                               className='footer-page-links__item'>bea.kaenel@ref-sgc.ch</a>
                        </div>
                        <div className="footer-page-actions footer-page-actions--mobile">
                            <a href='https://www.ref-sgc.ch/impressum' target={'_blank'}
                               className='footer-links footer-links--mobile' rel="noreferrer">
                                <FormattedMessage id="privacy-policy" defaultMessage="Impressum"/></a>
                            <a href='https://www.ref-sgc.ch/newsletter' target={'_blank'}
                               className='footer-links footer-links--mobile' rel="noreferrer">
                                <FormattedMessage id="about-us" defaultMessage="Mitglied werden"/></a>
                            <a href='https://www.ref-sgc.ch/sekretariat'
                               className='footer-links footer-links--mobile'><FormattedMessage id="contact-us"
                                                                                               defaultMessage="Contact"/></a>
                            <a href='https://gallusmedia.ch/' className='footer-page-links__item footer-page-links__item--modile'>
                                <FormattedMessage id="testimonial" defaultMessage="Liveübertragung und Podcast | unterstützt von Gallus Media AG"/>
                             </a>
                        </div>
                    </div>

                     {/* <a href='https://gallusmedia.ch/' */}
                     {/*  className='footer-page-links__item footer-page-links__item--desktop'>Liveübertragung und */}
                     {/*   Podcast | unterstützt von Gallus */}
                     {/*   Media AG */}
                     {/* </a> */}

                </div>
                <div className="footer-page-copyright">
                    <div className="social-media-wrap">
                        <YouTubeIcon/>
                        <InstagramIcon/>
                        <FacebookIcon/>
                    </div>
                    {/* <div className="copyrighting-mobile"> */}
                    {/*    <FormattedMessage id="company-name" */}
                    {/*                      defaultMessage="© Bistumsgv 2010 — 2023"/> */}
                    {/* </div> */}
                    <div className="footer-page-actions">
                        <a href='https://www.ref-sgc.ch/impressum'
                           className="footer-links"
                        ><FormattedMessage id="privacy-policy"
                                           defaultMessage="Impressum"/></a>
                        <a href='https://www.ref-sgc.ch/newsletter'
                           className="footer-links">
                            <FormattedMessage id="newsletter" defaultMessage="Mitglied werden"/></a>
                        <a href='https://gallusmedia.ch/'
                           className="footer-links"><FormattedMessage id="testimonial" defaultMessage="Liveübertragung und Podcast | unterstützt von Gallus Media AG"/></a>
                        {/* <FormattedMessage id="company-name" */}
                        {/*                  defaultMessage="© Bistumsgv 2010 — 2023"/> */}
                    </div>
                </div>
            </div>
        </div>

  )
}

export default Footer
