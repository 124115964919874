import React from 'react'
import { FormattedMessage } from 'react-intl'

// src
import Folder from '../../SVG/components/attachments/Folder'
import './Attachments.scss'
import Frame from '../../SVG/global/Frame'
import Document from '../../SVG/global/Document'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'

const Attachments = ({ event }) => {
  return (
        <div className="download-pdf-container">
            {event?.attachments?.length > 0
              ? !!event?.attachments?.length && event?.attachments?.map((attachment) => {
                  return (
                    <div key={attachment?.id} className='pdf-attachments'>
                        <div className='pdf-attachments__svg'><Folder/></div>

                        <p className={event.attachments.length ? 'wight-percent-100' : ''}> {attachment?.title} </p>
                        <span className='download-pdf' onClick={() => {
                          const element = document.createElement('a')
                          element.href = attachment?.meta?.download_url
                          element.setAttribute('target', '_blank')
                          document.body.appendChild(element)
                          element.click()
                        }
                        }><Frame/></span>
                    </div>
                  )
                })
              : <div className='pdf-no-attachments'>
                    <Document/>
                    <p>
                        <FormattedMessage id="no-attachments"
                                          defaultMessage="There are no attachments available to download"/>
                    </p>
                </div>}
            {event?.attachments?.length >= 1 ? <GradiantBtn
                className='attachments__btn'
                onClick={() => {
                  if (event?.attachments?.length === 1) {
                    const element = document.createElement('a')
                    element.href = event?.attachments[0]?.meta?.download_url
                    element.setAttribute('target', '_blank')
                    document.body.appendChild(element)
                    element.click()
                  } else {
                    for (let i = 0; i < event?.attachments?.length; i++) {
                      const element = document.createElement('a')
                      element.href = event?.attachments[i]?.meta?.download_url
                      element.setAttribute('target', '_blank')
                      document.body.appendChild(element)
                      element.click()
                    }
                  }
                }}>
                {event?.attachments?.length <= 1
                  ? <FormattedMessage id="download"
                                        defaultMessage="Download"/>
                  : <FormattedMessage id="download-all"
                                        defaultMessage="Download all"/>
                }
            </GradiantBtn> : ''}
        </div>
  )
}

export default Attachments
