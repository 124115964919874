import React from 'react'

const CopyLinkIcon = () => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M4.6 15.4002C3.46 14.2602 3.46 12.4068 4.6 11.2668L7.26667 8.60016L6 7.3335L3.33333 10.0002C1.49333 11.8402 1.49333 14.8268 3.33333 16.6668C5.17333 18.5068 8.16 18.5068 10 16.6668L12.6667 14.0002L11.4 12.7335L8.73333 15.4002C7.59333 16.5402 5.74 16.5402 4.6 15.4002ZM8 13.3335L13.3333 8.00016L12 6.66683L6.66667 12.0002L8 13.3335ZM10 3.3335L7.33333 6.00016L8.6 7.26683L11.2667 4.60016C12.4067 3.46016 14.26 3.46016 15.4 4.60016C16.54 5.74016 16.54 7.5935 15.4 8.7335L12.7333 11.4002L14 12.6668L16.6667 10.0002C18.5067 8.16016 18.5067 5.1735 16.6667 3.3335C14.8267 1.4935 11.84 1.4935 10 3.3335Z"
                fill="#282828"/>
        </svg>
  )
}

export default CopyLinkIcon
