import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../images/icons/SearchIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { onChangeFilterSearchValue } from '../../../redux/slices/eventsSlice/eventsSlice'

const HeaderActions = () => {
  // hooks

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [activeElement, setActiveElement] = useState('Home')
  const [showSearchField, setShowSearchField] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (searchValue === '') {
      dispatch(onChangeFilterSearchValue(''))
    }
  }, [searchValue])

  return (
        <div className="header-page-actions">
            <a href='/' className={`headerItemLink ${pathname === '/' ? 'headerItemLinkActive' : ''}`}
               onClick={() => setActiveElement('Home')}>Home</a>

            {/* <a href='/upcoming_events' */}
            {/*   className={`headerItemLink ${pathname === '/upcoming_events' ? 'headerItemLinkActive' : ''}`} */}
            {/*   // rel="noreferrer" onClick={() => setActiveElement('Upcoming events')}>Upcoming events</a> */}
            {/*   rel="noreferrer" onClick={() => setActiveElement('Upcoming events')}>Live-Übertragungen</a> */}

            {/* <a href='/recent_events' */}
            {/*   className={`headerItemLink ${pathname === '/recent_events' ? 'headerItemLinkActive' : ''}`} */}
            {/*   // rel="noreferrer" onClick={() => setActiveElement('Recent events')}>Recent events</a> */}
            {/*   rel="noreferrer" onClick={() => setActiveElement('Recent events')}>Aufzeichnungen</a> */}

            <a href='/recent_events'
               className={`headerItemLink ${activeElement === 'Learn more' ? 'headerItemLinkActive' : ''}`}
                // rel="noreferrer" onClick={() => setActiveElement('Learn more')}>Learn more</a>
               rel="noreferrer" onClick={() => setActiveElement('Learn more')}>Aufzeichnungen</a>

            <a href='/upcoming_events'
               className={`headerItemLink ${activeElement === 'Learn more' ? 'headerItemLinkActive' : ''}`}
                // rel="noreferrer" onClick={() => setActiveElement('Learn more')}>Learn more</a>
               rel="noreferrer" onClick={() => setActiveElement('Learn more')}>Nächste Live-Übertragungen</a>

            <div className='searchWrap'>
                {showSearchField &&
                    <TextField id="standard-basic"
                        // label="Search"
                               label="Suchen"
                               value={searchValue}
                               onChange={e => {
                                 setSearchValue(e.target.value)
                               }}
                               onKeyPress={(event) => {
                                 if (event.key === 'Enter') {
                                   if (searchValue) {
                                     dispatch(onChangeFilterSearchValue(searchValue))
                                     navigate('/events')
                                   } else {
                                     setShowSearchField(prev => !prev)
                                   }
                                 }
                               }}
                               variant="standard"/>}
                <div
                    onClick={() => {
                      if (searchValue) {
                        dispatch(onChangeFilterSearchValue(searchValue))
                        navigate('/events')
                      } else {
                        setShowSearchField(prev => !prev)
                      }
                    }}
                    className={`headerItemLink ${pathname === '/events' ? 'headerItemLinkActive' : ''}`}>
                    <SearchIcon fill={pathname === '/events' ? '$color-header-text' : null}/>
                </div>
            </div>

        </div>
  )
}

export default HeaderActions
