import { defaultApi } from '../splitApi'
import { addUser } from '../../slices/authSlice/authSlice'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI

export const userApi = defaultApi.injectEndpoints({
  tagTypes: ['User'],
  endpoints: (build) => ({
    getDetailUser: build.query({
      providesTags: [{ type: 'User' }],
      async queryFn (profileId, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ('auth/user/detail/')
        _queryApi.dispatch(addUser(response.data))
        return response
      }
    }),
    putDataUserAll: build.mutation({
      query: (arg) => ({
        url: 'auth/user/detail/',
        method: 'PUT',
        body: { ...arg }
      }),
      invalidatesTags: [{ type: 'User' }]

    }),
    addAvatarUser: (build.mutation({
      // query: (file) => ({
      //   url: 'auth/user/avatar/add/',
      //   method: 'POST',
      //   body: file,
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }),
      async queryFn (file, api, extraOptions, baseQuery) {
        const response = await axios.post(`${BASE_URL + BASE_URI}/auth/user/avatar/add/`, { ...file }, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${api.getState().authReducer.token}`
          }
        })
          .then((response) => {
            return response.data
          })
          .catch((error) => {
            if (error?.response) {
              return error?.response?.data?.Error
            }
          })
        return { data: response }
      },
      invalidatesTags: [{ type: 'User' }]
    })),
    changeAvatarUser: (build.mutation({
      // query: (file) => ({
      //   url: 'auth/user/avatar/',
      //   method: 'PUT',
      //   body: file,
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }),
      async queryFn (file, api, extraOptions, baseQuery) {
        const response = await axios.put(`${BASE_URL + BASE_URI}/auth/user/avatar/`, { ...file }, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${api.getState().authReducer.token}`
          }
        })
          .then((response) => {
            return response.data
          })
          .catch((error) => {
            if (error?.response) {
              return error?.response?.data?.Error
            }
          })
        return { data: response }
      },
      invalidatesTags: [{ type: 'User' }]
    }))
  })
})

export const {
  usePutDataUserAllMutation,
  useGetDetailUserQuery,
  useAddAvatarUserMutation,
  useChangeAvatarUserMutation
} = userApi
