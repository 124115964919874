import React from 'react'

import './card.scss'

const Card = ({ type, subtitle, title, description, isReverse, style, id, image, linkToSite, index }) => {
  return (
        <div className='card' style={{ ...style, paddingTop: index !== 0 ? '20px' : '' } || {}} id={id || ''}>
            <span
                className='card__text-subtitle card__text-subtitle--mobile'>{subtitle}</span>
            <h2 className={`card__title ${id === 'learn-more' ? 'learn-more-type' : ''}`}>{type}</h2>
            <div className={`card__info-container ${isReverse ? 'card__info-container--reverse' : ''}`}>
                <img src={image} alt="card" className='card__poster'/>
                <div className='card__text-container'>
                    <span className='card__text-subtitle'>{subtitle}</span>
                    <p className={`card__text-title ${id === 'learn-more' ? 'learn-more-title' : ''}`}>{title}</p>
                    <div className='card__text-description'>{description}</div>
                    {id === 'learn-more' && <a className='visit-website' href={linkToSite || ''}>Website der Kathedrale</a>}
                </div>
            </div>
        </div>
  )
}

export default Card
