import React from 'react'

const ShareIcon = () => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
            <path
                d="M15 0C14.2044 0 13.4413 0.316071 12.8787 0.87868C12.3161 1.44129 12 2.20435 12 3C12.0006 3.18815 12.0189 3.37583 12.0547 3.56055L4.93945 7.71094C4.39751 7.25184 3.71026 6.99992 3 7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10C0 10.7956 0.316071 11.5587 0.87868 12.1213C1.44129 12.6839 2.20435 13 3 13C3.70916 12.9986 4.3949 12.746 4.93555 12.2871L12.0547 16.4395C12.0189 16.6242 12.0006 16.8118 12 17C12 17.7956 12.3161 18.5587 12.8787 19.1213C13.4413 19.6839 14.2044 20 15 20C15.7956 20 16.5587 19.6839 17.1213 19.1213C17.6839 18.5587 18 17.7956 18 17C18 16.2044 17.6839 15.4413 17.1213 14.8787C16.5587 14.3161 15.7956 14 15 14C14.2902 14.0009 13.6037 14.2535 13.0625 14.7129L5.94531 10.5605C5.98108 10.3758 5.99939 10.1882 6 10C5.99939 9.81185 5.98108 9.62417 5.94531 9.43945L13.0605 5.28906C13.6025 5.74816 14.2897 6.00008 15 6C15.7956 6 16.5587 5.68393 17.1213 5.12132C17.6839 4.55871 18 3.79565 18 3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0Z"
                fill="#282828"/>
        </svg>
  )
}

export default ShareIcon
