import React, { useEffect, useState } from 'react'
// import { SocialShimmer } from 'react-content-shimmer'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Material UI components
import { CircularProgress } from '@mui/material'
import Paper from '@mui/material/Paper'

// Styles
import './EventAgenda.scss'
import colors from '../../Colors.scss'

// SVG
import ArrowPlayer from '../../SVG/global/ArrowPlayer'
import AgendaSvg from '../../SVG/pages/eventAgenda/AgendaSvg'
import Questions from '../../SVG/pages/question/Questions'

// Action
import Days, { handleFilterDay, isToDayCircle } from '../../components/Days/Days'
import ContextHelp from '../../components/ContextHelp/ContextHelp'

// Redux
import {
  addAgendaActive,
  addEventFromVideo,
  addNavigateToAgenda,
  addSelectedDate,
  addUrlFromVideo,
  onceScrollAgendaCheck,
  removeEventFromVideo
} from '../../redux/slices/globalValue/globalValue'

// Local components
import SpeakersAndChairsAgenda from './SpeakersAndChairsAgenda/SpeakersAndChairsAgenda'
import { useAgendaValue, useGlobalValue } from '../../hooks/redux/reduxHooks'
import SponsorAgenda from './components/SponsorAgenda'

const EventAgenda = () => {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // redux
  const { eventId, slug, onceScrollAgenda, selectedDate: selectedDateRedux } = useGlobalValue()
  const { agendaDays, agendaEvents } = useAgendaValue()

  // useState
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })
  const [selectedDate, setSelectedDate] = useState(selectedDateRedux)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if (!selectedDate && agendaDays) {
      isToDayCircle(agendaDays).then((value) => {
        setSelectedDate(value)
      })
    } else {
      dispatch(addSelectedDate(selectedDate))
      handleFilterDay(selectedDate)
    }
  }, [agendaDays, selectedDate])

  useEffect(() => {
    setLoader(false)
  }, [agendaEvents])

  return (
        <div className="event-agenda-main">
            <div className="event-agenda-inside">
                <div className="title-main">
                    <div className="title relative">
                        <AgendaSvg width='30' height='30'/><p><FormattedMessage id="event-agenda"
                                                                                defaultMessage="BaseLayout Agenda"/>&nbsp;
                        <span
                            onClick={(e) => {
                              setShowModalHelper({
                                open: !showModalHelper.open,
                                event: e.currentTarget
                              })
                            }}>
                    <Questions/>
                            {showModalHelper && <ContextHelp helpText={{
                              id: 3,
                              what: <FormattedMessage id='event-agenda-helper'
                                                        defaultMessage='An interactive event calendar with Speaker Profiles, Download Buttons, Ask Questions, Vote & Poll and much more.'/>
                            }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                             setShowModalHelper={setShowModalHelper}/>}
                  </span>
                    </p>
                    </div>
                </div>
                <Days setSelectedDate={setSelectedDate} setLoader={setLoader} eventsDay={agendaDays}
                />
                <div className='event-agenda-helper-mobile'
                     onClick={(e) => {
                       setShowModalHelper({
                         open: !showModalHelper.open,
                         event: e.currentTarget
                       })
                     }}>
                    <Questions width='25' height='25'/>
                    {showModalHelper && <ContextHelp helpText={{
                      id: 3,
                      what: <FormattedMessage id='event-agenda-helper'
                                                defaultMessage='An interactive event calendar with Speaker Profiles, Download Buttons, Ask Questions, Vote & Poll and much more.'/>
                    }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                     setShowModalHelper={setShowModalHelper}/>}
                </div>

                <div className='event-agenda-cards-main'>
                    {loader
                      ? <CircularProgress className="circularProgress"/>
                      : agendaEvents?.map((event) => {
                        const nameEvent = event.title.replace(/ /g, '-')
                        let firstElement = ''
                        if (event.agendaActive) {
                          firstElement = 'scroll-first-element-agenda'
                        }

                        if (!!firstElement && !onceScrollAgenda) {
                          setTimeout(() => {
                            const element = document.getElementsByClassName('scroll-first-element-agenda')[0]
                            const mainDiv = document.getElementsByClassName('event-agenda-cards-main')[0]
                            mainDiv?.scrollTo(0, element.offsetTop - element.offsetHeight - 40)
                            dispatch(onceScrollAgendaCheck(true))
                          }, 200)
                        }
                        return (
                                <Paper
                                    key={event.id}
                                    elevation={0}
                                    sx={{
                                      backgroundColor: event.agendaActive ? colors.highlighted_agenda : colors.color_cards_background,
                                      color: event.agendaActive ? colors.color_white : colors.color_black,
                                      border: `3px solid ${event.agendaActive ? colors.highlighted_agenda : colors.color_cards_background}`,
                                      width: '100%',
                                      borderRadius: event?.sponsor ? '20px 20px 5px 5px' : '20px'
                                    }}
                                    className={`agenda-cards-main wight-percent-100 ${firstElement}`}

                                >
                                    <div className='event-agenda-card'>

                                        <div
                                            className={`agenda-time  ${event.agendaActive ? 'border-white' : 'border-silver'}`}>
                                            {event.start.split(':')[0] +
                                                ':' +
                                                event.start.split(':')[1]}
                                            {event.agendaActive &&
                                                <div className="stream-icon-link-agenda cursor-pointer"
                                                     onClick={() => {
                                                       navigate(`/events/${event?.id}/${nameEvent}/agenda/`)
                                                     }}>
                                                </div>}
                                            {event?.video && event.video.status !== 'NOT_AUTHORIZED' &&
                                                <div className='cursor-pointer'
                                                     onClick={() => {
                                                       dispatch(addUrlFromVideo({
                                                         urlFromVideo: {
                                                           videoUrlHls: event?.video?.hls_url,
                                                           videoUrlEmbed: event?.video?.embed_code
                                                         }
                                                       }))
                                                       dispatch(addNavigateToAgenda(1))

                                                       if (event?.speakers.length === 1) {
                                                         dispatch(addEventFromVideo(event))
                                                       } else {
                                                         dispatch(removeEventFromVideo())
                                                       }
                                                       navigate(`/events/${eventId}/${slug}/${event?.id}/video/`)
                                                     }
                                                     }>
                                                    <ArrowPlayer
                                                        color={!event.agendaActive ? colors.color_agenda_play_button_not_active : colors.color_agenda_play_button_active}
                                                        colorArrow={event.agendaActive ? colors.color_agenda_play_button_active_arrow : colors.color_agenda_play_button_not_active_arrow}/>
                                                </div>}

                                        </div>

                                        <div className="event-agenda"
                                             onClick={() => {
                                               dispatch(addAgendaActive(event.agendaActive))
                                               navigate(`/events/${eventId}/${slug}/agenda/${event.id}/${nameEvent}/`, {
                                                 state: { id: event.id }
                                               })
                                             }}>
                                            <p className="title">{event.title}</p>
                                            <p className="para-text">
                                                {event.description}
                                            </p>
                                            <SpeakersAndChairsAgenda event={event}/>
                                        </div>
                                    </div>
                                    {event?.sponsor && <SponsorAgenda event={event}/>}
                                </Paper>
                        )
                      })}
                </div>
            </div>
        </div>
  )
}
export default EventAgenda
