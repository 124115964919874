import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetEventDescQuery, useGetSocialLinksQuery } from '../../redux/api/homePageApi/homePageApi'

import './Event.scss'
import ReactPlayer from 'react-player'
import SpeakerCard from './components/SpeakerCard'
import ShareIcon from '../../images/icons/ShareIcon'
import { useMediaQuery } from 'react-responsive'
import CopyLinkIcon from '../../images/icons/CopyLinkIcon'
import FileIcon from '../../images/icons/FileIcon'
import BackArrowIcon from '../../images/icons/BackArrowIcon'
import CardBg from '../../images/icons/CardBg'
import { changeOpenReminderModal, onChangeReminderId } from '../../redux/slices/eventsSlice/eventsSlice'
import { useDispatch, useSelector } from 'react-redux'
import ReminderModal from '../../components/ReminderModal'
import { shareUrlHelper, shareUrlIcon } from '../../helper/shareUrlHelper/shareUrlHelper'
import { Avatar } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getMonth } from '../../parser'
import { useGetIsStartedEvent } from '../../components/BaseLayout/BaseLayout'

const Event = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const descriptionRef = useRef(null)
  const match = location.pathname.match(/\/(\d+)\//)

  const id = match[1]
  const { data, refetch } = useGetEventDescQuery(id)
  const {
    data: socialLinks = []
  } = useGetSocialLinksQuery()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const { isOpenReminderModal } = useSelector((state) => state.eventSliceReducer)
  const { isStartedEvent, setIsStartedEvent } = useGetIsStartedEvent()

  const [viewAllSpeakers, setViewAllSpeakers] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [activeSocialLinksId, setActiveSocialLinksId] = useState(null)
  const [isLiveEvent, setIsLiveEvent] = useState(false)

  // const [elementSize, setElementSize] = useState({
  //   width: 0,
  //   height: 0
  // })

  const HtmlRenderer = ({ htmlContent }) => {
    return <div ref={descriptionRef}>
            {htmlContent?.map((el, i) => {
              // eslint-disable-next-line react/jsx-key
              return <div key={i} dangerouslySetInnerHTML={{ __html: el.value }}
                            className='html-renderer-style'
                />
            })}
        </div>
  }

  useEffect(() => {
    if (descriptionRef.current) {
      function handleResize () {
        // const { clientWidth, clientHeight } = descriptionRef.current
        // setElementSize({
        //   width: clientWidth,
        //   height: clientHeight
        // })
      }

      window.addEventListener('resize', handleResize)

      handleResize()

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [descriptionRef, data])

  // const getSpeakerContainerHeight = () => {
  //   if (!elementSize.height) return '265px'
  //
  //   if (isTabletOrMobile) {
  //     return viewAllSpeakers ? 'auto' : elementSize.height
  //   } else {
  //     return elementSize.height
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const inputDateStr = data?.schedule_begin
  const inputDate = new Date(inputDateStr).toUTCString()

  // const months = [
  //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  // ]

  // const outputDateStr = `Streamed on: ${months[inputDate.getMonth()]} ${inputDate.getDate()}, ${inputDate.getFullYear()}, ${inputDate.toLocaleTimeString([], {
  const outputDateStr = `Gestreamt am: ${getMonth(inputDate)} ${inputDate.split(' ')[1]}, ${inputDate.split(' ')[3]}, ${inputDateStr?.substring(11, 19)}`

  const isBackEnabled = window.history.length > 2
  const isResent = data?.event_schedule === 'recent'

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://sdk.snapkit.com/js/v1/create.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (!data) return
    const currentDate = new Date()
    const date1 = new Date(currentDate)
    const date2 = new Date(data?.schedule_begin)
    const utcDateCurrent = new Date(date1.toISOString())
    const utcDateStart = new Date(date2.toISOString())
    // console.log('EVENT: start date is: ' + utcDateStart)
    const isLiveEvent = utcDateCurrent > utcDateStart
    setIsLiveEvent(isLiveEvent)
  }, [data])

  useEffect(() => {
    if (isStartedEvent) {
      refetch()
      setTimeout(() => {
        setIsStartedEvent(false)
      }, 1000)
    }
  }, [isStartedEvent])

  const renderBanner = () => {
    if (isStartedEvent || isLiveEvent) {
      if (data?.stream?.hls_url) {
        return <div className='event__video-player'>
                    <ReactPlayer
                        url={data?.stream?.hls_url}
                        controls={true}
                        width="100%"
                        height="100%"
                        light={data?.image?.download_url}
                        playing={false}
                    />
                </div>
      } else if (data?.stream?.embed_code) {
        return <div className='iframe-container'>
                    <iframe style={{
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                      overflow: 'hidden'
                    }} src={data?.stream?.embed_code}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                    ></iframe>
                </div>
      } else if (data?.image?.download_url) {
        return <div className='event__video-player'>
                    <img
                        src={data.image?.download_url}
                        alt="event-card"
                    />
                </div>
      } else {
        return <div className='event__video-player'>
                    <div className="event__background-img">
                        <CardBg/>
                    </div>
                </div>
      }
    } else {
      if (data?.image?.download_url) {
        return <div className='event__video-player'>
                    <img
                        src={data.image?.download_url}
                        alt="event-card"
                    />
                </div>
      } else if (data?.stream?.hls_url) {
        return <div className='event__video-player'>
                    <ReactPlayer
                        url={data?.stream?.hls_url}
                        controls={true}
                        width="100%"
                        height="100%"
                        light={data?.image?.download_url}
                        playing={false}
                    />
                </div>
      } else if (data?.stream?.embed_code) {
        return <div className='iframe-container'>
                    <iframe style={{
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                      overflow: 'hidden'
                    }} src={data?.stream?.embed_code}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                    ></iframe>
                </div>
      } else {
        return <div className='event__video-player'>
                    <div className="event__background-img">
                        <CardBg/>
                    </div>
                </div>
      }
    }
  }

  return (
        <div className='event'>
            {isOpenReminderModal && !isResent && <ReminderModal/>}
            {isBackEnabled && <div className='event__back-icon' onClick={() => navigate(-1)}>
                <BackArrowIcon/>
            </div>}
            {renderBanner()}
            <div className='event__data-container'>
                <h1 className='event__title'>{data?.title}</h1>
                <p className='event__date'>{outputDateStr}</p>
                <div className='event__description-container'>
                    <div className='event__speakers-container-with-button'>
                        {!!data?.speakers?.length &&
                            <div className='event__speakers-container' style={{
                              zIndex: !isTabletOrMobile ? '3' : ''
                            }}>
                                {data?.speakers?.slice(0, isTabletOrMobile ? viewAllSpeakers ? undefined : 4 : undefined).map(speaker => {
                                  return (
                                        <div key={speaker?.id} className='speakers-overlay-main'>
                                            <div className='speakers-overlay-button'
                                                 onClick={(event) => event.stopPropagation()}>
                                                <SpeakerCard key={speaker?.id} speaker={speaker}/>
                                            </div>

                                            <div className="speakers-overlay"
                                                 style={{ left: '105px', top: '-80px', margin: '80px' }}
                                                 onClick={(event) => event.stopPropagation()}>
                                                <div className="speaker-title">
                                                    <div className="speaker-avatar">
                                                        <Avatar
                                                            sx={{ width: 36, height: 36 }}
                                                            src={speaker.avatar.download_url}
                                                        />
                                                    </div>
                                                    <div className="speaker-name">
                                                        <p className="name">
                                                            {speaker?.full_name}
                                                        </p>
                                                        <p className="type">
                                                            {speaker?.title}
                                                        </p>
                                                    </div>
                                                </div>
                                                {speaker?.biography &&
                                                    <div className="speaker-bio">
                                                        {speaker?.biography}
                                                    </div>
                                                }
                                                {!!(speaker?.linkedin || speaker?.facebook || speaker?.twitter) &&
                                                    <div className="speakers-links">
                                                        {speaker?.linkedin && <a href={speaker?.linkedin}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16"
                                                                 viewBox="0 0 16 16" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                      d="M6.28567 5.9795H8.76167V7.21283C9.11834 6.5035 10.033 5.86616 11.407 5.86616C14.041 5.86616 14.6663 7.27816 14.6663 9.86883V14.6668H11.9997V10.4588C11.9997 8.9835 11.643 8.1515 10.735 8.1515C9.47568 8.1515 8.95234 9.04816 8.95234 10.4582V14.6668H6.28567V5.9795ZM1.71301 14.5535H4.37967V5.86616H1.71301V14.5535ZM4.76167 3.0335C4.76177 3.25701 4.71745 3.47832 4.63127 3.68455C4.54509 3.89079 4.41878 4.07784 4.25967 4.23483C3.93727 4.55525 3.50088 4.7346 3.04634 4.7335C2.5926 4.73319 2.15722 4.5543 1.83434 4.2355C1.67582 4.07797 1.54993 3.89071 1.4639 3.68445C1.37788 3.47819 1.33339 3.25698 1.33301 3.0335C1.33301 2.58216 1.51301 2.15016 1.83501 1.8315C2.1576 1.51227 2.59317 1.3333 3.04701 1.3335C3.50167 1.3335 3.93767 1.51283 4.25967 1.8315C4.58101 2.15016 4.76167 2.58216 4.76167 3.0335Z"
                                                                      fill="$color-header-text"/>
                                                            </svg>
                                                        </a>}

                                                        {speaker?.facebook && <a href={speaker?.facebook}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8"
                                                                 height="16"
                                                                 viewBox="0 0 8 16" fill="none">
                                                                <g clip-path="url(#clip0_3186_16052)">
                                                                    <path
                                                                        d="M2.04221 16V8.49234H0V5.78922H2.04221V3.4804C2.04221 1.66611 3.24856 0 6.02823 0C7.15368 0 7.98589 0.10488 7.98589 0.10488L7.92032 2.62914C7.92032 2.62914 7.07159 2.6211 6.14543 2.6211C5.14303 2.6211 4.98244 3.07014 4.98244 3.81544V5.78922H8L7.8687 8.49234H4.98244V16H2.04221Z"
                                                                        fill="$color-header-text"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_3186_16052">
                                                                        <rect width="8" height="16" fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </a>}

                                                        {speaker?.twitter && <a href={speaker?.twitter}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16"
                                                                 viewBox="0 0 16 16" fill="none">
                                                                <path
                                                                    d="M15.7623 3.29122C15.2057 3.53789 14.6077 3.70455 13.979 3.77989C14.6277 3.39175 15.113 2.78087 15.3443 2.06122C14.7349 2.42321 14.0679 2.67802 13.3723 2.81456C12.9046 2.31513 12.285 1.98411 11.6099 1.87287C10.9347 1.76164 10.2417 1.87642 9.63849 2.19939C9.03525 2.52236 8.55552 3.03546 8.27377 3.65902C7.99202 4.28259 7.92402 4.98172 8.08033 5.64789C6.84546 5.58589 5.63742 5.26492 4.53462 4.70582C3.43181 4.14673 2.45889 3.362 1.679 2.40256C1.41233 2.86256 1.259 3.39589 1.259 3.96389C1.2587 4.47522 1.38462 4.97872 1.62559 5.42971C1.86655 5.8807 2.21511 6.26525 2.64033 6.54922C2.14718 6.53353 1.66492 6.40028 1.23367 6.16055V6.20055C1.23362 6.91771 1.48169 7.61281 1.93579 8.16788C2.38989 8.72296 3.02205 9.10384 3.725 9.24589C3.26752 9.3697 2.78789 9.38793 2.32233 9.29922C2.52066 9.9163 2.907 10.4559 3.42724 10.8425C3.94749 11.2291 4.57561 11.4433 5.22367 11.4552C4.12356 12.3188 2.76492 12.7873 1.36633 12.7852C1.11859 12.7853 0.871051 12.7708 0.625 12.7419C2.04465 13.6547 3.69723 14.1391 5.385 14.1372C11.0983 14.1372 14.2217 9.40522 14.2217 5.30122C14.2217 5.16789 14.2183 5.03322 14.2123 4.89989C14.8199 4.46054 15.3443 3.91649 15.761 3.29322L15.7623 3.29122Z"
                                                                    fill="$color-header-text"/>
                                                            </svg>
                                                        </a>}

                                                    </div>}
                                            </div>
                                        </div>
                                  )
                                })}
                            </div>}

                        {isTabletOrMobile && !!data?.speakers?.length &&
                            <div className='event__view-all'
                                 onClick={() => setViewAllSpeakers(!viewAllSpeakers)}>{viewAllSpeakers ? 'View less' : 'View all'}</div>}
                    </div>

                    <div>
                        <HtmlRenderer htmlContent={data?.body}/>
                    </div>

                    <div className='event__interaction-button-wrap'>
                        {!isTabletOrMobile && !isResent &&
                            // <button className='event__interaction-button event__interaction-button--reminder'
                            <button className='event__interaction-button'
                                    onClick={() => {
                                      dispatch(onChangeReminderId(data.id))
                                      dispatch(changeOpenReminderModal(true))
                                    }}>
                                {/* Set a reminder */}
                                Erinnerung aktivieren
                            </button>}
                        {!isTabletOrMobile &&
                            <button className='event__interaction-button'
                                    onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                                    onMouseLeave={() => setActiveSocialLinksId(null)}>
                                <ShareIcon/>
                                <FormattedMessage id="button-share" defaultMessage="Teilen"/>
                            </button>
                        }
                        <div className='event__social-links-container'
                             style={{
                               top: isResent ? '60px' : '',
                               opacity: !(!isTabletOrMobile && activeSocialLinksId === data?.id) ? '0' : '1'
                             }}
                             onClick={event => event.stopPropagation()}
                             onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                             onMouseLeave={() => setActiveSocialLinksId(null)}
                        >
                            <div
                                onClick={event => event.stopPropagation()}
                                onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                                onMouseLeave={() => setActiveSocialLinksId(null)}
                                className='social-links-wrap'>
                                {!!socialLinks?.length &&
                                    <div className='social-links-wrap social-links-wrap--pt'>
                                        <p key='copy-link' style={{ cursor: 'pointer' }} onClick={() => {
                                          navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_HOST}events/${data.id}/${data.slug}`).then(() => {
                                            setIsCopied(true)

                                            setTimeout(() => {
                                              setIsCopied(false)
                                            }, 2000)
                                          })
                                        }}
                                           className='social-links-item social-links-item--download'>
                                            <CopyLinkIcon/>
                                            {/* {isCopied ? 'Copied' : 'Copy link'} */}
                                            {isCopied ? 'Copied' : 'Link kopieren'}
                                        </p>
                                        {socialLinks.map((el, i) => {
                                          const url = process.env.REACT_APP_BASE_HOST + 'events/' + data?.id + '/' + data?.slug

                                          if (el.label === 'SNAPCHAT') {
                                            return <p key={i}
                                                          className='social-links-item snapchat-share-button'
                                                          data-share-url={url}>
                                                    {shareUrlIcon()[`${el.label}`]}
                                                    {el.label}
                                                </p>
                                          } else {
                                            return <a href={shareUrlHelper(url)[`${el.label}`]}
                                                          target="_blank" key={i}
                                                          className='social-links-item'
                                                          rel="noopener noreferrer">
                                                    {shareUrlIcon()[`${el.label}`]}
                                                    {el.label}
                                                </a>
                                          }
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                        {isTabletOrMobile && data?.attachments && data?.attachments.map(el => {
                          return (
                                <a key={el.id} href={el?.meta?.download_url}
                                   className='event__navigation_button event__navigation_button--white' target='_blank' rel="noreferrer">
                                    <FileIcon/>
                                    {el.title}
                                </a>
                          )
                        })}
                        {!isTabletOrMobile && <button
                            className='event__interaction-button'
                            style={{ position: 'relative', zIndex: activeSocialLinksId ? 0 : 3 }}
                            onClick={(event) => {
                              event.stopPropagation()
                              navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_HOST}${location.pathname.slice(1)}`).then(() => {
                                setIsCopied(true)

                                setTimeout(() => {
                                  setIsCopied(false)
                                }, 2000)
                              })
                            }}
                        >
                            <CopyLinkIcon/>
                            {/* {isCopied ? 'Copied' : 'Copy link'} */}
                            {isCopied ? 'Copied' : 'Link kopieren'}
                        </button>}
                    </div>

                    {!!data?.sponsors.length &&
                        <div className='event__sponsors-wrap'>
                            {data?.sponsors?.map(el => {
                              return <div key={el?.id}>
                                    <img src={el?.image?.download_url} alt={el.title}/>
                                </div>
                            })}
                        </div>
                    }
                </div>
            </div>
            <div className='event__navigation_button-wrap'>
                {!!data?.attachments.length && <div className='event__decorative-line'></div>}
                {isTabletOrMobile && !isResent &&
                    // <button className='event__interaction-button event__interaction-button--reminder'
                    <button className='event__interaction-button'
                            onClick={() => {
                              dispatch(onChangeReminderId(data.id))
                              dispatch(changeOpenReminderModal(true))
                            }}>
                        {/* Set a reminder */}
                        Erinnerung aktivieren
                    </button>
                }
                {/* <button className='event__navigation_button'> */}
                {/*    Lyrics */}
                {/* </button> */}
                {!isTabletOrMobile && data?.attachments && data?.attachments.map(el => {
                  return (
                        <a key={el.id} href={el?.meta?.download_url}
                           className='event__navigation_button event__navigation_button--white'>
                            <FileIcon/>
                            {el.title}
                        </a>
                  )
                })}
                {isTabletOrMobile &&
                    <button className='event__interaction-button'
                            onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                            onMouseLeave={() => setActiveSocialLinksId(null)}
                    >
                        <ShareIcon/>
                        Share
                    </button>}
                <div className='event__social-links-container event__social-links-container--adaptive'
                     style={{
                       top: isResent ? '160px' : '',
                       opacity: !(isTabletOrMobile && activeSocialLinksId === data?.id) ? '0' : '1'
                     }}
                     onClick={event => event.stopPropagation()}
                     onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                     onMouseLeave={() => setActiveSocialLinksId(null)}
                >
                    <div
                        onClick={event => event.stopPropagation()}
                        onMouseEnter={() => setActiveSocialLinksId(data?.id)}
                        onMouseLeave={() => setActiveSocialLinksId(null)}
                        className='social-links-wrap'>
                        {!!socialLinks?.length &&
                            <div className='social-links-wrap social-links-wrap--pt'>
                                <p key='copy-link' style={{ cursor: 'pointer' }} onClick={() => {
                                  navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_HOST}events/${data.id}/${data.slug}`).then(() => {
                                    setIsCopied(true)

                                    setTimeout(() => {
                                      setIsCopied(false)
                                    }, 2000)
                                  })
                                }}
                                   className='social-links-item social-links-item--download'>
                                    <CopyLinkIcon/>
                                    {/* {isCopied ? 'Copied' : 'Copy link'} */}
                                    {isCopied ? 'Copied' : 'Link kopieren'}
                                </p>
                                {socialLinks.map((el, i) => {
                                  const url = process.env.REACT_APP_BASE_HOST + 'events/' + data?.id + '/' + data?.slug

                                  return <a href={shareUrlHelper(url)[`${el.label}`]}
                                              target="_blank" key={i}
                                              className='social-links-item'
                                              rel="noopener noreferrer">
                                        {shareUrlIcon()[`${el.label}`]}
                                        {el.label}
                                    </a>
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Event
