import React from 'react'
import Box from '@mui/material/Box'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { FormattedMessage } from 'react-intl'
import { InputBase } from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'

const Input = ({ setShowEmoji, showEmoji, file, setFile, setMsgToSend, msgToSend, focusInput }) => {
  return (
      <Box className="send-msg">
          {/* {chosenEmoji ? ( */}
          {/*    <span>You chose: {chosenEmoji.emoji}</span> */}
          {/* ) : ( */}
          {/*    <span>No emoji Chosen</span> */}
          {/* )} */}
          <div className="send-msg-input-flex-center" style={{ width: '100%' }}>

              <EmojiEmotionsOutlinedIcon

                  onClick={() => {
                    setShowEmoji(!showEmoji)
                  }}
                  sx={{ color: 'action.active', mr: 1, my: 0.5, cursor: 'pointer' }}/>
              <input type="file"
                     name="file"
                     id="fileMsg" style={{ display: 'none' }}
                     hidden
                     onChange={(e) => {
                       setFile(e.target.files[0])
                     }}/>
              <AttachFileOutlinedIcon
                  className="file-chose-chat-icon"
                  onClick={() => {
                    document.getElementById('fileMsg').click()
                  }}
                  sx={{ color: 'action.active', mr: 1, my: 0.5 }}/>
              {!!file && (
                  <>
                      <p>{file.name}</p>
                      <p className="delete-file-chat-icon"
                         onClick={() => {
                           const fileInput = document.getElementById('fileMsg')
                           fileInput.value = null
                           setFile('')
                         }}><RemoveCircleIcon/></p>
                  </>)}
              <FormattedMessage id="placeholder-send-message" defaultMessage="Send a message...">
                  {
                      placeholder =>
                          <InputBase style={{ width: '100%' }} name="message" value={msgToSend}
                                     placeholder={`${placeholder}`}
                                     id="input-with-sx"
                                     hidden
                                     type="input"
                                     variant="standard"
                                     onChange={(e) => {
                                       setMsgToSend(e.target.value)
                                     }}
                                     inputRef={input => focusInput && input?.focus()}
                          />
                  }
              </FormattedMessage>

          </div>
          <div className="send-msg-input-flex-center">
              <button className="btn-reset-style">
                  <SendOutlinedIcon color="primary"/>
              </button>
          </div>
      </Box>
  )
}

export default React.memo(Input)
