import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// Mui
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import { Avatar } from '@mui/material'

// Intl
import { FormattedMessage } from 'react-intl'

// Style
import './ParticipantProfile.scss'
import { GradiantBtn } from '../../../assets/muiStyles/Button-style'
import colors from '../../../Colors.scss'

// SVG
import AddressSVG from '../../../SVG/pages/participant/ParticipantProfile/AddressSVG'
import InstitutionSVG from '../../../SVG/pages/participant/ParticipantProfile/InstitutionSVG'
import MarkerSVG from '../../../SVG/pages/participant/ParticipantProfile/MarkerSVG'

// Redux
import { useGetAttendeeProfileEventQuery } from '../../../redux/api/participtantApi/participantApi'
import { useGetVisibleDetailsQuery } from '../../../redux/api/agendaApi/agendaApi'

export default function ParticipantProfile () {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { profileId, scrollInfo } = location.state

  // redux
  const userDetail = useSelector(state => state.authReducer.user)
  const slug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // api
  const { data: profileData, isLoading, isFetching } = useGetAttendeeProfileEventQuery({ eventId, profileId })
  const { data: allowedProperty } = useGetVisibleDetailsQuery(eventId)
  return (
        <>
            <div className="participant-profile-main">
                {isLoading || isFetching && <CircularProgress className={'circularProgress'}/>}
                {!isLoading && !isFetching && (
                    <div className="participant-profile-inside">
                        <div className="participants-title-main-profile">
                            <div className="participants-title-profile">
                                <ArrowBackOutlinedIcon className="cursor-pointer arrow" onClick={() => navigate(`/events/${eventId}/${slug}/participants/`,
                                  {
                                    state: scrollInfo
                                  }
                                )}/>

                            </div>
                            {/* <div> */}
                            {/*    <MoreVertOutlinedIcon onClick={() => console.log("side")} /> */}
                            {/* </div> */}
                        </div>
                        <div className="participant-profile-detail">
                            {allowedProperty.avatar_enabled ? <Avatar
                                className="participants-profile-img"
                                alt="dp"
                                src={
                                    profileData !== null && profileData?.avatar?.download_url
                                }
                                sx={{ width: 100, height: 100 }}
                            /> : ''}
                            {/* <img
              height="156px"
              width="156px"
              src={profileData !== null && profileData.avatar?.meta.download_url}
              alt="dp"
            /> */}
                            {allowedProperty.full_name_enabled ? <p className="user-name">{profileData?.full_name}</p> : ''}
                            {allowedProperty.email_enabled ? <p className="user-email">{profileData?.email}</p> : ''}

                        </div>
                        <div className="participant-address-detail">
                            {allowedProperty.job_title_enabled && profileData?.job_title && (
                                <>
                                    <div className='participant-detail'>
                                      <AddressSVG width={25} height={24} color={colors.color_blue}></AddressSVG>
                                        <p className="mT2px">{profileData?.job_title}</p>
                                    </div>
                                    <br/>
                                </>
                            )}
                            {allowedProperty.institution_enabled && profileData?.institution !== '' && (
                                <>
                                    <div className='participant-detail'>
                                        <InstitutionSVG width={25} height={24} color={colors.color_blue}/>
                                        <p className="mT2px">{profileData?.institution}</p>
                                    </div>
                                    <br/>
                                </>
                            )}

                            {!!profileData?.city.length | !!profileData?.country.length && (
                                <div className='participant-detail'>
                                    <MarkerSVG width={25} height={24} color={colors.color_blue}></MarkerSVG>
                                    <p className="mT2px">{allowedProperty.city_enabled && !!profileData?.city.length ? profileData?.city : ''}, {allowedProperty.country_enabled && !!profileData?.country ? profileData?.country : ''}</p>
                                </div>
                            )}

                        </div>
                        {allowedProperty.biography_enabled ? <div className="participant-profile-detail">
                            <p className="user-desc">{profileData.biography}</p>
                        </div> : ''}

                        <div className="participant-option">
                            <GradiantBtn
                                style={{ padding: 12 }}
                                variant="contained"
                                onClick={() => {
                                  if (profileData?.id !== userDetail?.id) {
                                    navigate(`/events/${eventId}/${slug}/participants/chat/${profileData.id}`)
                                  } else {
                                    navigate(`/events/${eventId}/${slug}/inbox/`)
                                  }
                                }}
                            >
                                <p className="mT2px"><FormattedMessage id="start-chat" defaultMessage="Start Chat"/></p>
                            </GradiantBtn>

                        </div>
                    </div>
                )}
            </div>
        </>
  )
}
