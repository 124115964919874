import React, { createContext, useContext, useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'

import './BaseLayout.scss'
import { useLocation } from 'react-router-dom'
import { useGetBannerEventsQuery } from '../../redux/api/homePageApi/homePageApi'
import StartEventModal from '../StartEventModal'

const StartedEventContext = createContext(null)

const BaseLayout = ({ children }) => {
  const { pathname } = useLocation()
  const { data: events } = useGetBannerEventsQuery()

  const [modalVisible, setModalVisible] = useState(false)
  const [eventId, setEventId] = useState(null)
  const [isStartedEvent, setIsStartedEvent] = useState(false)
  const [isCloseOverlay, setIsCloseOverlay] = useState(false)

  const getTime = (timestamp) => {
    const date = new Date(timestamp)

    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    const time = date.getUTCHours() + ':' + date.getUTCMinutes() + ':' + date.getUTCSeconds()

    return {
      year,
      month,
      day,
      time
    }
  }

  useEffect(() => {
    if (events) {
      const now = new Date().toUTCString()
      events.forEach(event => {
        if (!(getTime(now).year === getTime(event.schedule_begin).year &&
                    getTime(event.schedule_begin).month === getTime(now).month &&
                    getTime(event.schedule_begin).day === getTime(now).day)) return

        const startEventSecondTime = getTime(event.schedule_begin).time
          .split(':')
          .reduce((acc, val, index) => acc + parseInt(val) * [3600, 60, 1][index], 0)

        const nowTime = getTime(now).time
          .split(':')
          .reduce((acc, val, index) => acc + parseInt(val) * [3600, 60, 1][index], 0)

        const timeDiff = (startEventSecondTime - nowTime) * 1000
        const timeOutCloseOverlay = (startEventSecondTime - nowTime) * 1000 + 10000

        if (startEventSecondTime > nowTime) {
          localStorage.setItem('eventId', event.id)
          const timeoutId = setTimeout(() => {
            const id = localStorage.getItem('eventId')
            const pathname = localStorage.getItem('pathname')

            if (!pathname.includes(id) && pathname !== '/') {
              setEventId(event.id)
              setModalVisible(true)
            }

            setIsStartedEvent(true)
          }, timeDiff)

          const timeOutCloseOverlayId = setTimeout(() => {
            setIsCloseOverlay(true)
          }, timeOutCloseOverlay)

          return () => {
            clearTimeout(timeoutId)
            clearTimeout(timeOutCloseOverlayId)
          }
        }
      })
    }
  }, [events])

  useEffect(() => {
    localStorage.setItem('pathname', pathname)
  }, [pathname])

  return (
        <div className='containerLayout'>
            <StartedEventContext.Provider value={{ isStartedEvent, setIsStartedEvent, isCloseOverlay }}>
                <Header/>
                {modalVisible && <StartEventModal id={eventId} setModalVisible={(val) => setModalVisible(val)}/>}
                {children}
                {pathname !== '/reminder' && <Footer/>}

            </StartedEventContext.Provider>

        </div>
  )
}

export default BaseLayout

export const useGetIsStartedEvent = () => {
  return useContext(StartedEventContext)
}
