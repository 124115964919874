import React, { useEffect } from 'react'
import UpcomingEvents from '../HomePage/UpcomingEvents/UpcomingEvents'
import BackArrowIcon from '../../images/icons/BackArrowIcon'
import { useNavigate } from 'react-router-dom'

import './UpcomingEvent.scss'
import {
  onChangeFilterEventIdUpcoming,
  onChangeFilterOffsetUpcoming,
  onChangeFilterSearchValue
} from '../../redux/slices/eventsSlice/eventsSlice'
import CloseIcon from '../../images/icons/CloseIcon'
import Pagination from '@mui/material/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import ReminderModal from '../../components/ReminderModal'
import { CookieBanner } from '../../components/cookieBanner/CookieBanner'

const UpcomingPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { limit } = useSelector((state) => state.eventSliceReducer.filterUpcoming)
  const totalEvents = useSelector((state) => state.eventSliceReducer.totalEvents)
  const searchValue = useSelector((state) => state.eventSliceReducer.searchValue)
  const { isOpenReminderModal } = useSelector((state) => state.eventSliceReducer)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  return (
        <div className='upcoming-events'>
            <div className='resent-events__back-icon' onClick={() => navigate(-1)}>
                <BackArrowIcon/>
            </div>

            {isOpenReminderModal && <ReminderModal/>}

            <div className='resent-events__search-bar-wrap'>
                <input type="text"
                       className='resent-events__search-bar'
                       placeholder='Suchen'
                       value={searchValue}
                       onChange={(e) => {
                         dispatch(onChangeFilterEventIdUpcoming(null))
                         dispatch(onChangeFilterSearchValue(e.target.value))
                       }}
                />
                <div className='resent-events__close-icon-wrap'
                     onClick={() => dispatch(onChangeFilterSearchValue(''))}>
                    <CloseIcon/>
                </div>
            </div>
            <div className='event-with-pagination'>
                <UpcomingEvents/>

                {totalEvents && limit && totalEvents / limit !== 0 &&
                    <div className='resent-events__pagination-controller'>
                        <Pagination
                            count={totalEvents < limit ? 1 : Math.ceil(totalEvents / limit)}
                            color="primary"
                            onChange={(e, val) => dispatch(onChangeFilterOffsetUpcoming((val - 1) * limit))}/>
                    </div>}
            </div>
            <CookieBanner/>
        </div>
  )
}

export default UpcomingPage
