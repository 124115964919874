import FacebookShareIcon from '../../images/icons/FacebookShareIcon'
import WhatsappIcon from '../../images/icons/WhatsappIcon'
import EmailIcon from '../../images/icons/EmailIcon'
import TwitterIcon from '../../images/icons/TwitterIcon'
import LinkedinIcon from '../../images/icons/LinkedinIcon'
import TelegramIcon from '../../images/icons/TelegramIcon'
import RedditIcon from '../../images/icons/RedditIcon'
import ThumblIcon from '../../images/icons/ThumblIcon'
import SnapchatIcon from '../../images/icons/SnapchatIcon'

export const shareUrlHelper = (url) => {
  return {
    FACEBOOK: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    WHATSAPP: `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
    EMAIL: `mailto:?body=${encodeURIComponent(url)}`,
    X: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    LINKEDIN: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
    REDDIT: `https://www.reddit.com/submit?url=${encodeURIComponent(url)}`,
    TUMBLER: `https://www.tumblr.com/share?url=${encodeURIComponent(url)}`,
    SNAPCHAT: `https://www.snapchat.com/share?url=${encodeURIComponent(url)}`,
    TELEGRAM: `https://telegram.me/share/url?url=${encodeURIComponent(url)}`
  }
}

export const shareUrlIcon = () => {
  return {
    FACEBOOK: <FacebookShareIcon/>,
    WHATSAPP: <WhatsappIcon/>,
    EMAIL: <EmailIcon/>,
    X: <TwitterIcon/>,
    LINKEDIN: <LinkedinIcon/>,
    REDDIT: <RedditIcon/>,
    TUMBLER: <ThumblIcon/>,
    SNAPCHAT: <SnapchatIcon/>,
    TELEGRAM: <TelegramIcon/>
  }
}
