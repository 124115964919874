import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resentEventsFilters: {},
  upcomingEventsFilters: {},
  totalEvents: 0,
  totalEventsRecent: 0,
  totalEventsUpcoming: 0,
  isOpenReminderModal: false,
  searchValue: '',
  filterUpcoming: {
    eventId: null,
    offset: 0,
    limit: 9
  },
  dateUpcoming: {
    from: null,
    to: null
  },
  filterRecent: {
    eventId: null,
    offset: 0,
    limit: 9
  },
  dateRecent: {
    from: null,
    to: null
  },
  filterAll: {
    eventId: null,
    offset: 0,
    limit: 9
  },
  dateAll: {
    from: null,
    to: null
  },
  reminderId: null
}

const eventSlice = createSlice({
  name: 'eventSlice',
  initialState,
  reducers: {
    onChangeFilterEventIdRecent: (state, action) => {
      state.filterRecent.eventId = action.payload
    },
    onChangeFilterEventIdUpcoming: (state, action) => {
      state.filterUpcoming.eventId = action.payload
    },
    onChangeFilterEventIdAll: (state, action) => {
      state.filterAll.eventId = action.payload
    },
    onChangeFilterOffsetRecent: (state, action) => {
      state.filterRecent.offset = action.payload
    },
    onChangeFilterOffsetUpcoming: (state, action) => {
      state.filterUpcoming.offset = action.payload
    },
    onChangeFilterOffsetAll: (state, action) => {
      state.filterAll.offset = action.payload
    },
    onChangeFilterLimit: (state, action) => {
      state.filter.limit = action.payload
    },
    onChangeFilterSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    onChangeReminderId: (state, action) => {
      state.reminderId = action.payload
    },
    onChangeDateFromRecent: (state, action) => {
      state.dateRecent.from = action.payload
    },
    onChangeDateFromUpcoming: (state, action) => {
      state.dateUpcoming.from = action.payload
    },
    onChangeDateFromAll: (state, action) => {
      state.dateAll.from = action.payload
    },
    onChangeDateToRecent: (state, action) => {
      state.dateRecent.to = action.payload
    },
    onChangeDateToUpcoming: (state, action) => {
      state.dateUpcoming.to = action.payload
    },
    onChangeDateToAll: (state, action) => {
      state.dateUpcoming.to = action.payload
    },
    onChangTotalEvents: (state, action) => {
      state.totalEvents = action.payload
    },
    onChangeTotalEventsRecent: (state, action) => {
      state.totalEventsRecent = action.payload
    },
    onChangeTotalEventsUpcoming: (state, action) => {
      state.totalEventsUpcoming = action.payload
    },
    changeOpenReminderModal: (state, action) => {
      state.isOpenReminderModal = action.payload
    }
  }
})

export const eventSliceReducer = eventSlice.reducer
export const {
  onChangeFilterEventIdRecent,
  onChangeFilterEventIdUpcoming,
  onChangeFilterEventIdAll,
  onChangeFilterOffsetRecent,
  onChangeFilterLimit,
  onChangeFilterOffsetUpcoming,
  onChangeFilterOffsetAll,
  onChangeFilterSearchValue,
  onChangeReminderId,
  onChangeDateFromRecent,
  onChangeDateFromUpcoming,
  onChangeDateFromAll,
  onChangeDateToRecent,
  onChangeDateToUpcoming,
  onChangeDateToAll,
  onChangTotalEvents,
  onChangeTotalEventsRecent,
  onChangeTotalEventsUpcoming,
  changeOpenReminderModal
} = eventSlice.actions
