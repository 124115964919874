import React from 'react'

const SearchIcon = ({ fill }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill={fill || 'none'}>
            <g clipPath="url(#clip0_3382_14635)">
                <path
                    d="M26.6674 24.1677H25.3507L24.8841 23.7177C26.8841 21.3844 27.9174 18.2011 27.3507 14.8177C26.5674 10.1844 22.7007 6.48439 18.034 5.91772C10.984 5.05105 5.05072 10.9844 5.91738 18.0344C6.48405 22.7011 10.1841 26.5677 14.8174 27.3511C18.2007 27.9177 21.384 26.8844 23.7174 24.8844L24.1674 25.3511V26.6677L31.2507 33.7511C31.934 34.4344 33.0507 34.4344 33.734 33.7511C34.4174 33.0677 34.4174 31.9511 33.734 31.2677L26.6674 24.1677ZM16.6674 24.1677C12.5174 24.1677 9.16738 20.8177 9.16738 16.6677C9.16738 12.5177 12.5174 9.16772 16.6674 9.16772C20.8174 9.16772 24.1674 12.5177 24.1674 16.6677C24.1674 20.8177 20.8174 24.1677 16.6674 24.1677Z"
                    fill={fill || '#282828'}/>
            </g>
            <defs>
                <clipPath id="clip0_3382_14635">
                    <rect width="40" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
  )
}

export default SearchIcon
