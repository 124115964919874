import React from 'react'

const Logo = () => {
  return (
  // <svg xmlns="http://www.w3.org/2000/svg" width="151" height="56" viewBox="0 0 88 56" fill="none">
           <svg width="302px" height="112px" viewBox="0 0 378 140" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Centrum</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
            <g transform="translate(117.000000, 68.000000)">
                <path d="M169.9,35.8 C169.9,34 169.9,32.3 169.9,30.5 C169.9,29.8 170,29.1 170.8,29.2 C173,29.6 175.5,28 177.6,30 C178.1,30.5 178.5,29.9 178.8,29.6 C181.8,27.6 186.3,29.5 185.9,33.8 C185.7,36.1 185.9,38.4 185.8,40.7 C185.8,41.3 186.2,42.3 184.9,42.4 C183.6,42.4 183.8,41.4 183.8,40.7 C183.8,38.5 183.8,36.2 183.8,34 C183.8,31.7 182.9,30.4 181.3,30.5 C179.8,30.5 178.8,31.9 178.7,34.1 C178.7,36.4 178.7,38.7 178.7,41 C178.7,41.6 179,42.5 177.9,42.5 C176.8,42.6 176.7,41.8 176.7,41 C176.7,38.5 176.7,36.1 176.7,33.7 C176.7,32 176.1,30.7 174.3,30.5 C172.8,30.3 171.8,31.8 171.7,33.8 C171.7,36.3 171.7,38.7 171.7,41.1 C171.7,41.8 171.7,42.5 170.7,42.5 C169.7,42.5 169.7,41.8 169.7,41.1 C169.9,39.3 169.9,37.6 169.9,35.8" fill="#21385C"></path>
                <path d="M53.7,12.4 C53.7,10.6 53.7,8.9 53.7,7.1 C53.7,6.1 54,5.5 55.1,5.8 C56.4,6.2 57.6,5.1 58.8,5.6 C60.7,6.4 62.3,6.2 64.3,5.7 C67.4,4.9 69.5,6.9 69.6,10.2 C69.6,12.6 69.6,15 69.6,17.3 C69.6,18.1 69.9,19.1 68.6,19 C67.5,19 67.7,18.1 67.7,17.3 C67.7,14.9 67.7,12.5 67.7,10.2 C67.7,8.5 67.1,7.2 65.3,6.9 C63.9,6.7 62.7,8.2 62.7,10.2 C62.7,12.6 62.7,15.1 62.7,17.5 C62.7,18.2 62.7,18.9 61.8,19 C60.6,19.1 60.7,18.2 60.7,17.5 C60.7,15.1 60.7,12.6 60.7,10.2 C60.7,8.4 59.6,7 58.2,7 C56.8,7 55.7,8.3 55.7,10.1 C55.7,12.5 55.7,15 55.7,17.4 C55.7,18.2 55.8,19 54.7,19 C53.5,19 53.7,18.1 53.7,17.4 C53.7,15.8 53.7,14.1 53.7,12.4" fill="#21385C"></path>
                <path d="M37.5,42.1 C36.6,42.3 35.8,42.6 34.9,42.7 C31.4,43.1 29.3,41.3 29.3,37.7 C29.3,36.3 29.2,35 29.3,33.6 C29.5,30.6 31.6,28.9 35,28.9 C37.9,29 39.6,30.7 39.7,33.7 C39.7,36.7 39.7,39.7 39.7,42.7 C39.7,45.9 38,47.6 34.8,47.7 C33.7,47.7 32.6,47.7 31.5,47.7 C31.1,47.7 30.7,47.5 30.7,47 C30.7,46.5 31.1,46.4 31.5,46.3 C32.4,46.3 33.3,46.3 34.2,46.3 C37,46.1 37.9,44.9 37.5,42.1" fill="#21385C"></path>
                <path d="M135.4,13.2 C134.1,14.6 132.6,15.7 133,17.9 C133.1,18.6 132.7,19.1 131.9,19.1 C131,19.1 131,18.5 131,17.8 C131,12.7 131,7.6 131,2.5 C131,1.8 131,1.2 131.9,1.2 C132.8,1.2 133,1.8 133,2.6 C133,5.9 133,9.1 133,12.7 C135,11.2 136.2,9.3 137.7,7.8 C138.2,7.3 138.7,6.7 139.2,6.2 C139.7,5.7 140.5,5.4 141,6 C141.6,6.6 140.9,7.1 140.5,7.5 C139.6,8.5 138.8,9.6 137.8,10.5 C136.8,11.4 136.8,12.1 137.7,13.2 C138.8,14.5 139.7,16 140.5,17.4 C140.8,17.9 141.9,18.6 140.8,19.2 C139.9,19.7 139.3,19 138.8,18.3 C137.9,16.9 137,15.6 136.1,14.2 C135.9,13.8 135.6,13.6 135.4,13.2" fill="#21385C"></path>
                <path d="M173,10.2 C173,7.7 173,5.3 173,2.8 C173,2.1 172.8,1.2 174,1.2 C175,1.2 174.9,2 175,2.7 C175.1,3.8 174.5,5.2 175.3,5.8 C175.8,6.2 177.2,5.6 178.1,5.6 C181.6,5.5 183.3,7.1 183.3,10.6 C183.3,12.8 183.3,15 183.3,17.1 C183.3,17.9 183.6,19.1 182.2,19.1 C180.8,19.1 181.3,17.8 181.3,17.1 C181.2,14.9 181.3,12.6 181.3,10.4 C181.3,8.4 180.2,7.1 178.5,7 C176.7,6.9 175.1,8.4 175,10.3 C174.9,12.3 175,14.4 175,16.4 C175,16.9 175,17.4 175,17.8 C175,18.5 174.8,19.2 173.9,19.1 C172.9,19.1 173.1,18.4 173.1,17.8 C173,15.3 173,12.7 173,10.2" fill="#21385C"></path>
                <path d="M52.3,37.3 C52.3,38.6 52.3,39.9 52.3,41.2 C52.3,41.7 52.2,42.6 51.6,42.4 C49.4,41.7 47.3,43.3 45.1,42.5 C43.2,41.9 42,40.5 41.9,38.5 C41.9,36.5 42.9,35.1 44.7,34.5 C45.7,34.2 46.9,34.4 47.9,34.2 C48.7,34.1 50,35 50.1,33.4 C50.2,31.9 49.4,30.9 47.9,30.7 C46.8,30.5 45.6,30.6 44.4,30.6 C43.9,30.6 43.2,30.7 43.2,29.9 C43.2,29.2 43.9,29.2 44.4,29.1 C45.2,29.1 46,29.1 46.8,29.1 C50.7,29.2 52.1,30.6 52.1,34.6 C52.4,35.5 52.4,36.4 52.3,37.3 Z" fill="#21385C"></path>
                <path d="M167.2,36.1 C167.2,37.7 167.2,39.4 167.2,41 C167.2,42.2 166.6,42.9 165.4,42.3 C164.5,41.8 164,42.4 163.3,42.6 C160.1,43.3 156.3,42.1 156.7,37.4 C156.9,35.2 156.7,32.9 156.7,30.7 C156.7,29.9 156.8,29.3 157.7,29.3 C158.7,29.3 158.7,30 158.7,30.7 C158.7,33.1 158.7,35.6 158.7,38 C158.7,40.1 159.7,41.3 161.4,41.4 C163.2,41.5 164.8,40.1 164.9,38.2 C165,35.8 164.9,33.4 164.9,31.1 C164.9,30.3 164.7,29.3 166,29.3 C167.2,29.3 167.1,30.3 167.1,31.1 C167.2,32.7 167.2,34.4 167.2,36.1" fill="#21385C"></path>
                <path d="M66.9,35.9 C66.9,35.1 66.8,34.3 66.9,33.5 C67.1,31.4 68.2,29.9 70.2,29.3 C72.3,28.7 74.4,28.6 76.1,30.3 C77.3,31.5 77.5,33.1 77,34.7 C76.4,36.3 75.2,37.3 73.5,37.4 C72.4,37.5 71.3,37.5 70.2,37.4 C68.9,37.3 68.7,38 69,39 C69.3,40 70,40.7 71.1,41 C72.4,41.3 73.7,41.2 75,41.2 C75.4,41.2 75.9,41.2 75.8,41.8 C75.8,42.3 75.4,42.5 75,42.5 C73.4,42.5 71.9,42.6 70.3,42.4 C68.3,42.1 67,40.3 66.9,38.2 C66.8,37.4 66.9,36.7 66.9,35.9" fill="#21385C"></path>
                <path d="M111.3,36 C111.4,34.7 111.1,33.4 111.5,32.1 C112.7,28.7 118,27.7 120.4,30.3 C121.6,31.5 121.9,33 121.3,34.6 C120.7,36.2 119.5,37.1 117.8,37.3 C116.8,37.4 115.8,37.4 114.7,37.3 C112.8,37.2 113,38.3 113.5,39.4 C114.2,41 115.7,41 117.1,41.1 C118.1,41.2 119.9,40.5 119.9,41.6 C119.9,43.1 118.1,42.2 117.1,42.3 C115.2,42.5 113.4,42.3 112.1,40.7 C110.9,39.4 111.4,37.6 111.3,36" fill="#21385C"></path>
                <path d="M30.5,12.7 C30.5,11.7 30.4,10.7 30.5,9.6 C30.8,7 33.1,5.4 36.3,5.5 C39,5.6 40.6,7.1 40.7,9.8 C40.8,11.4 40.8,12.9 40.7,14.5 C40.5,17.7 38.4,19.3 34.8,19.2 C31.9,19.1 30.5,17.5 30.5,14.3 C30.4,13.8 30.4,13.3 30.5,12.7 Z" fill="#21385C"></path>
                <path d="M123.8,35.7 C123.8,34 123.8,32.3 123.8,30.6 C123.8,30 123.9,29 124.6,29.2 C126.4,29.9 127.9,28.7 129.7,28.9 C132.3,29.1 134.1,30.5 134.2,33.2 C134.4,35.8 134.3,38.5 134.3,41.2 C134.3,42 134.1,42.5 133.1,42.5 C132,42.5 132.2,41.7 132.2,41.1 C132.2,38.7 132.2,36.3 132.2,34 C132.2,31.8 131.1,30.5 129.4,30.4 C127.6,30.3 125.9,31.9 125.9,33.9 C125.8,36.3 125.9,38.7 125.9,41 C125.9,41.7 126.1,42.6 124.9,42.5 C123.8,42.4 124,41.7 124,41 C123.8,39.2 123.8,37.5 123.8,35.7" fill="#21385C"></path>
                <path d="M84.5,19.1 C80,19.1 78.7,17.8 78.7,13.3 C78.7,12.7 78.8,12.1 78.7,11.5 C78.2,7.9 80.6,5.1 85.2,5.6 C87.4,5.9 89,7.6 89,9.9 C89,12.2 87.3,13.8 85,13.9 C84.3,13.9 83.6,13.8 83,13.9 C82.2,14.1 80.9,13.1 80.8,14.8 C80.7,16.3 81.5,17.3 83,17.5 C84.1,17.7 85.2,17.6 86.2,17.7 C86.8,17.7 87.6,17.5 87.6,18.4 C87.6,19.2 86.8,19.1 86.2,19.1 C85.6,19.1 85,19.1 84.5,19.1" fill="#21385C"></path>
                <path d="M186.2,12.7 C186.2,11.7 186.1,10.8 186.2,9.8 C186.4,7 188.8,5.4 192.3,5.6 C194.8,5.7 196.5,7.5 196.4,9.9 C196.3,12.1 194.8,13.8 192.6,13.9 C191.7,14 190.8,13.9 190,13.9 C189.4,14 188.5,13.5 188.3,14.4 C188.1,15.2 188.3,16 188.9,16.7 C189.9,17.8 191.3,17.5 192.5,17.7 C193.4,17.8 195,17 195,18.4 C195,19.7 193.3,19 192.4,19.1 C187.3,19.2 186.2,18 186.2,12.7" fill="#21385C"></path>
                <path d="M79.5,35.8 C79.5,34 79.5,32.3 79.5,30.5 C79.5,30 79.5,29.1 80.2,29.3 C82.3,29.9 84.2,28.6 86.3,29.1 C88.2,29.5 89.7,31 89.8,33.1 C89.9,35.8 89.9,38.5 89.9,41.3 C89.9,42 89.8,42.7 88.8,42.7 C87.8,42.7 87.8,42 87.8,41.3 C87.8,38.9 87.8,36.4 87.8,34 C87.8,32 86.7,30.7 85.1,30.6 C83.3,30.5 81.6,32.1 81.6,34.1 C81.6,36.3 81.6,38.4 81.6,40.6 C81.6,41.4 82.1,42.7 80.5,42.7 C79,42.7 79.7,41.4 79.6,40.7 C79.4,38.9 79.5,37.3 79.5,35.8" fill="#21385C"></path>
                <path d="M9.6,12.6 C9.6,11.8 9.6,11 9.6,10.2 C9.8,7.1 12.4,5.4 16.1,5.7 C18.5,5.9 20.1,7.9 19.9,10.4 C19.7,12.4 18.2,13.9 16.2,14 C15.3,14.1 14.3,14 13.3,14 C12.8,14 12,13.6 11.7,14.4 C11.4,15.1 11.7,15.7 12,16.3 C12.9,17.9 14.5,17.5 16,17.7 C16.8,17.8 18.4,17.1 18.4,18.3 C18.4,19.7 16.8,19 15.9,19.1 C12.1,19.2 10.3,18.3 9.8,16 C9.6,14.9 9.6,13.7 9.6,12.6 C9.6,12.6 9.6,12.6 9.6,12.6" fill="#21385C"></path>
                <path d="M117.6,19.1 C113.1,19.1 111.8,17.7 111.8,13.3 C111.8,12.7 111.9,12.1 111.8,11.5 C111.4,8.1 113.7,4.9 118.8,5.7 C120.8,6 122.2,7.9 122.1,10 C122,12.1 120.5,13.8 118.5,14 C117.4,14.1 116.3,14.1 115.2,14 C113.5,13.9 113.6,14.8 114,15.9 C114.4,16.9 115.1,17.6 116.3,17.7 C117.4,17.8 118.5,17.8 119.6,17.8 C120.1,17.8 120.8,17.7 120.8,18.5 C120.8,19.2 120.1,19.2 119.6,19.2 C118.9,19.1 118.3,19.1 117.6,19.1" fill="#21385C"></path>
                <path d="M98.7,35.9 C98.7,34.9 98.6,34 98.7,33 C98.9,31 100.7,29.2 102.9,28.9 C105.9,28.5 108.1,29.6 108.7,31.8 C108.9,32.5 109.3,33.4 108.3,33.7 C107.3,34.1 107,33.4 106.8,32.4 C106.5,30.9 105.3,30.1 103.8,30.2 C102.2,30.4 100.9,31.3 100.7,33 C100.5,34.9 100.6,36.8 100.7,38.7 C100.8,40.2 101.8,41 103.3,41.1 C105,41.3 106.3,40.8 106.8,39.1 C107,38.2 107.2,37.3 108.2,37.7 C109.4,38.1 108.9,39.1 108.6,39.9 C107.7,42.1 105.3,43.1 102.2,42.5 C99.8,42.1 98.7,40.6 98.7,38.1 C98.7,37.4 98.7,36.7 98.7,35.9 Z" fill="#21385C"></path>
                <path d="M160.4,12.7 C160.4,11.9 160.4,11.1 160.4,10.3 C160.4,8.1 161.5,6.7 163.5,5.9 C165.5,5.1 168.4,5.6 169.6,6.9 C170.2,7.6 170.6,8.3 170.7,9.3 C170.8,9.9 170.7,10.4 170,10.5 C169.4,10.6 168.9,10.4 168.8,9.7 C168.5,7.7 167.4,6.8 165.5,7 C163.6,7.2 162.6,8.8 162.6,11.2 C162.6,12.5 162.6,13.8 162.6,15.1 C162.6,16.8 163.6,17.7 165.2,17.9 C166.9,18.1 168.2,17.5 168.7,15.8 C169,15 169,14 170.1,14.4 C171.3,14.8 170.8,15.8 170.5,16.6 C169.6,18.9 167.3,19.8 163.9,19.2 C161.8,18.8 160.6,17.2 160.6,14.8 C160.4,14.1 160.4,13.4 160.4,12.7" fill="#21385C"></path>
                <path d="M23.6,12.9 C23.6,11.9 23.6,10.9 23.6,9.8 C23.6,8.5 24.1,6.8 21.6,7.1 C21.1,7.2 20.8,6.8 20.9,6.3 C21,5.8 21.4,5.6 21.8,5.7 C23.2,5.9 23.6,5.3 23.9,4 C24.5,1.8 28.2,0.4 30.4,1.2 C30.7,1.3 31,1.5 31,1.9 C31,2.4 30.6,2.6 30.2,2.7 C29.6,2.8 29,2.8 28.4,2.7 C27.5,2.7 26.8,3 26.3,3.6 C25.9,4.1 25.6,4.6 25.8,5.3 C26,5.9 26.6,5.6 27.1,5.6 C27.7,5.7 28.5,5.5 28.4,6.4 C28.3,7.1 27.6,7.1 27.1,7 C25.8,6.9 25.7,7.5 25.7,8.6 C25.7,11.5 25.7,14.4 25.7,17.4 C25.7,18.1 25.8,19 24.7,19 C23.6,19 23.6,18.2 23.6,17.4 C23.6,16 23.6,14.4 23.6,12.9" fill="#21385C"></path>
                <path d="M4,42.5 C3,42.5 2.1,42.5 1.1,42.5 C0.6,42.5 0.3,42.3 0.3,41.8 C0.3,41.2 0.8,41.2 1.2,41.2 C2.6,41.2 4.1,41.3 5.5,41.2 C7,41.1 8.5,40.7 8.5,38.9 C8.5,37.2 7.3,36.5 5.6,36.4 C4.9,36.4 4.3,36.2 3.6,36.1 C1.7,36 0.4,35.3 0.3,33.3 C0.2,31.5 1.5,30 3.3,29.5 C4.9,29.1 6.5,29.3 8.2,29.3 C8.7,29.3 9.2,29.4 9.2,30 C9.2,30.7 8.7,30.7 8.2,30.7 C7,30.7 5.9,30.6 4.7,30.8 C3.4,30.9 2.3,31.7 2.3,33.1 C2.2,34.8 3.6,34.7 4.7,34.9 C5.6,35 6.5,35 7.3,35.2 C9.4,35.7 10.7,37.4 10.4,39.3 C10.2,41.1 8.7,42.4 6.6,42.6 C5.8,42.5 4.9,42.5 4,42.5" fill="#21385C"></path>
                <path d="M103.8,11.6 C103.8,10.9 103.8,10.2 103.8,9.6 C103.9,8.2 104,6.8 101.8,7.2 C101.4,7.3 101,7 101,6.5 C101,5.9 101.5,5.7 102,5.8 C103.6,6.1 104,5.3 103.8,3.9 C103.7,3.1 104.1,2.8 104.9,2.8 C105.7,2.8 105.9,3.3 105.8,3.9 C105.5,5.8 106.6,6 108,5.8 C108.3,5.8 108.7,5.8 109,5.8 C109.4,5.8 109.8,6 109.8,6.5 C109.8,7 109.4,7.1 109,7.2 C107.9,7.5 106.4,6.5 105.9,7.8 C105.5,8.8 105.8,10.1 105.8,11.2 C105.8,12.2 105.8,13.1 105.8,14.1 C105.8,16.9 106.6,17.7 109.4,17.8 C109.7,17.8 109.9,17.8 110.2,17.8 C110.7,17.8 111.2,17.8 111.3,18.5 C111.3,19.1 110.8,19.2 110.3,19.3 C106.9,19.5 103.4,18.9 103.8,13.8 C103.8,13 103.8,12.3 103.8,11.6" fill="#21385C"></path>
                <path d="M19.2,42.5 C15.5,42.5 14.1,41.1 14.1,37.3 C14.1,35.6 14,33.9 14.1,32.2 C14.2,31.1 14,30.4 12.6,30.6 C12.1,30.7 11.3,30.7 11.3,30 C11.2,29.1 12.1,29.1 12.6,29.2 C14,29.4 14.2,28.6 14.1,27.5 C14,26.8 14,26.1 15.1,26.1 C16.1,26.1 16.2,26.7 16.1,27.4 C15.9,28.9 16.6,29.3 17.9,29.1 C18.4,29 18.9,29.1 19.3,29.1 C19.7,29.1 20.1,29.3 20.1,29.8 C20.1,30.3 19.8,30.5 19.3,30.5 C19.2,30.5 19.2,30.5 19.1,30.5 C18.1,30.6 16.7,30 16.2,30.8 C15.7,31.6 16,32.9 16,34 C16,35.2 16,36.4 16,37.7 C16,40.1 16.9,41 19.3,41.1 C19.7,41.1 20.1,41.1 20.5,41.1 C20.9,41.1 21.4,41.1 21.4,41.6 C21.4,42.2 21,42.4 20.5,42.4 C20.2,42.5 19.7,42.5 19.2,42.5" fill="#21385C"></path>
                <path d="M137.9,35.3 C137.9,34.3 137.8,33.4 137.9,32.4 C138,31.1 137.8,30.2 136.2,30.5 C135.8,30.6 135.2,30.6 135.1,30 C135,29.2 135.6,29.1 136.2,29.1 C137.7,29.3 138.1,28.6 137.9,27.2 C137.8,26.6 137.9,26 138.8,26 C139.6,26 139.9,26.5 139.8,27.2 C139.5,29 140.5,29.2 141.9,29.1 C142.6,29.1 143.7,28.8 143.7,29.8 C143.8,31 142.6,30.6 142,30.5 C140,30.2 139.6,31.1 139.7,32.9 C139.8,34.3 139.7,35.8 139.7,37.2 C139.7,40.3 140.4,41 143.5,41.2 C144.1,41.2 145.1,40.8 145.1,41.8 C145.1,42.8 144.1,42.4 143.5,42.5 C142.8,42.5 142.1,42.5 141.5,42.5 C139.2,42.3 137.8,40.8 137.8,38.4 C137.8,37.3 137.9,36.3 137.9,35.3" fill="#21385C"></path>
                <path d="M57.7,33.5 C57.7,35.7 57.8,37.9 57.7,40 C57.7,40.9 58.3,42.3 56.7,42.4 C55,42.5 55.6,41 55.6,40.1 C55.5,36 55.5,31.9 55.6,27.9 C55.6,26.8 55.6,25.8 54,26.1 C53.5,26.2 52.8,26.2 52.8,25.4 C52.8,24.7 53.5,24.7 54,24.6 C54.6,24.6 55.2,24.6 55.8,24.6 C57.1,24.5 57.6,25 57.6,26.4 C57.6,28.8 57.7,31.1 57.7,33.5" fill="#21385C"></path>
                <path d="M63.9,33.7 C63.9,36.1 63.9,38.6 63.9,41.1 C63.9,41.9 63.8,42.6 62.7,42.6 C61.6,42.6 61.8,41.8 61.8,41.1 C61.8,36.7 61.8,32.2 61.8,27.8 C61.8,26.6 61.6,26 60.3,26.2 C59.8,26.3 59.1,26.3 59,25.6 C59,24.7 59.8,24.8 60.4,24.8 C61,24.8 61.6,24.8 62.2,24.8 C63.4,24.7 63.9,25.2 63.9,26.5 C63.9,28.8 63.9,31.2 63.9,33.7" fill="#21385C"></path>
                <path d="M2.2,14.1 C2.2,15.4 2.2,16.7 2.2,18 C2.2,18.7 2,19.2 1.2,19.2 C0.4,19.2 0.2,18.7 0.2,18 C0.2,14.3 0.2,10.7 0.2,7 C0.2,6.4 0.5,5.7 1.1,5.9 C2.9,6.7 4.5,5.3 6.1,5.7 C6.7,5.8 7.3,5.8 7.9,6 C8.5,6.2 9.4,6.5 9,7.3 C8.7,8.1 7.9,7.8 7.3,7.5 C4.3,6.4 2.2,7.8 2.2,11 C2.2,12 2.2,13 2.2,14.1" fill="#21385C"></path>
                <path d="M151,12.4 C151,10.6 151,8.9 151,7.1 C151,6.5 151.1,5.5 151.8,5.8 C153.6,6.5 155.2,5.2 156.9,5.6 C157.4,5.7 157.9,5.6 158.3,5.8 C158.9,6.1 160.1,6.3 159.8,7.1 C159.5,8.2 158.5,7.6 157.8,7.3 C155.4,6.3 153.1,7.8 153,10.4 C152.9,12.7 153,15 153,17.3 C153,18.1 153.2,19.1 151.9,19.1 C150.7,19.1 151,18.1 151,17.4 C151,15.8 151,14.1 151,12.4" fill="#21385C"></path>
                <path d="M43.1,12.4 C43.1,10.6 43.1,8.9 43.1,7.1 C43.1,5.9 43.5,5.4 44.7,5.9 C46,6.5 47.1,5.3 48.4,5.6 C49.3,5.8 50.3,5.6 51.2,6.1 C51.7,6.4 52.3,6.6 52,7.3 C51.7,7.9 51.1,7.8 50.6,7.7 C49.9,7.4 49.3,7 48.5,7 C46.7,7.1 45.2,8.6 45.2,10.5 C45.2,12.9 45.2,15.2 45.2,17.6 C45.2,18.4 45.3,19.2 44.2,19.2 C43,19.2 43.2,18.3 43.2,17.6 C43.1,15.8 43.1,14.1 43.1,12.4" fill="#21385C"></path>
                <path d="M91.3,12.4 C91.3,10.6 91.3,8.9 91.3,7.1 C91.3,6.5 91.3,5.5 92.1,5.8 C94,6.5 95.6,5.2 97.4,5.6 C97.9,5.7 98.5,5.7 99,5.9 C99.6,6.1 100.5,6.4 100.1,7.3 C99.8,8 99.1,7.7 98.5,7.5 C95.2,6.3 93.4,7.6 93.4,11.1 C93.4,13.1 93.4,15.2 93.4,17.2 C93.4,18 93.7,19 92.4,19.1 C91,19.2 91.5,18 91.4,17.3 C91.2,15.7 91.3,14.1 91.3,12.4" fill="#21385C"></path>
                <path d="M146.9,35.8 C146.9,34 146.9,32.3 146.9,30.5 C146.9,30 146.9,29.1 147.5,29.3 C149.6,29.9 151.7,28.5 153.8,29.1 C154.2,29.2 154.6,29.3 154.9,29.5 C155.4,29.8 155.9,30.1 155.6,30.8 C155.3,31.3 154.8,31.2 154.3,31.1 C153.5,30.9 152.9,30.3 152,30.4 C150.2,30.5 148.9,32 148.8,34 C148.8,36.3 148.8,38.6 148.8,40.9 C148.8,41.6 149,42.5 147.8,42.5 C146.5,42.5 146.9,41.6 146.9,40.9 C146.8,39.2 146.9,37.5 146.9,35.8" fill="#21385C"></path>
                <path d="M147.7,12.5 C147.7,14.3 147.7,16 147.7,17.8 C147.7,18.6 147.4,19.1 146.5,19.1 C145.6,19.1 145.6,18.4 145.6,17.8 C145.6,14.9 145.5,11.9 145.6,9 C145.6,7.7 145.5,6.9 143.9,7.1 C143.5,7.2 142.8,7.2 142.9,6.5 C142.9,5.7 143.5,5.7 144.1,5.7 C144.7,5.7 145.3,5.8 145.9,5.7 C147.4,5.5 147.8,6.2 147.7,7.6 C147.6,9.2 147.7,10.9 147.7,12.5" fill="#21385C"></path>
                <path d="M75.8,12.4 C75.8,14.2 75.8,15.9 75.8,17.7 C75.8,18.5 75.6,19.1 74.7,19.1 C73.7,19.1 73.7,18.4 73.7,17.7 C73.7,14.8 73.7,11.8 73.7,8.9 C73.7,7.7 73.6,6.9 72.1,7.1 C71.6,7.2 70.9,7.2 71,6.3 C71.1,5.7 71.6,5.6 72.1,5.6 C72.4,5.6 72.8,5.6 73.1,5.6 C75.8,5.6 75.8,5.6 75.8,8.2 C75.8,9.7 75.8,11.1 75.8,12.4" fill="#21385C"></path>
                <path d="M74.4,3.2 C73.7,3.3 73.2,2.9 73.2,2.2 C73.2,1.5 73.7,1.2 74.4,1.2 C75.2,1.2 75.8,1.5 75.7,2.3 C75.7,3.1 75.1,3.3 74.4,3.2" fill="#21385C"></path>
                <path d="M147.7,2.3 C147.5,3 147.1,3.2 146.4,3.2 C145.7,3.2 145.1,3 145.1,2.3 C145.1,1.5 145.7,1.2 146.5,1.2 C147.2,1.2 147.6,1.6 147.7,2.3" fill="#21385C"></path>
                <path d="M24.3,40.9 C25,40.9 25.5,41 25.6,41.8 C25.7,42.6 25.1,42.6 24.5,42.6 C23.9,42.6 23.3,42.6 23.3,41.8 C23.3,41.1 23.8,40.8 24.3,40.9" fill="#21385C"></path>
                <path d="M37.7,35.9 C37.6,36.8 37.8,37.8 37.5,38.8 C36.9,40.5 35.4,41.5 33.8,41.2 C32.1,40.9 31.4,39.7 31.4,38 C31.4,36.6 31.4,35.3 31.4,33.9 C31.5,31.8 33.1,30.2 35,30.3 C36.7,30.4 37.7,31.7 37.7,33.9 C37.7,34.5 37.7,35.2 37.7,35.9" fill="#FFFFFF"></path>
                <path d="M48.3,35.6 C49.3,35.5 50.4,35.3 50.3,37.3 C50.1,39.7 48.9,41.3 46.8,41.2 C45.2,41.2 43.9,40 43.9,38.4 C43.9,36.5 45.3,35.6 48.3,35.6" fill="#FFFFFF"></path>
                <path d="M71.2,36 C70.2,35.9 68.7,36.7 68.8,34.4 C69,32 70.4,30.2 72.4,30.3 C74,30.3 75.2,31.7 75.1,33.3 C75.1,35.1 73.9,36 71.2,36" fill="#FFFFFF"></path>
                <path d="M115.1,36 C114.2,36 113,36.3 113.2,34.3 C113.4,32 114.7,30.3 116.7,30.3 C118.2,30.3 119.5,31.7 119.5,33.2 C119.5,35.1 118.2,36 115.1,36" fill="#FFFFFF"></path>
                <path d="M38.7,12.2 C38.7,13.1 38.8,14 38.7,14.8 C38.5,16.5 36.9,18 35.3,17.9 C33.6,17.9 32.6,16.6 32.5,14.6 C32.5,13.2 32.5,11.9 32.5,10.5 C32.5,8.5 34,6.9 35.8,6.9 C37.6,6.9 38.7,8.2 38.7,10.5 C38.7,11.1 38.7,11.6 38.7,12.2 Z" fill="#FFFFFF"></path>
                <path d="M82.6,12.6 C81.6,12.7 80.5,12.8 80.7,10.8 C80.9,8.5 82.2,6.8 84.2,6.8 C85.8,6.8 87,8.1 87,9.7 C86.9,11.8 85.7,12.6 82.6,12.6" fill="#FFFFFF"></path>
                <path d="M188.2,11.1 C188.2,8.6 189.6,6.9 191.5,6.9 C193.1,6.9 194.3,8.1 194.4,9.7 C194.4,11.7 193.2,12.6 190.4,12.6 C189.3,12.6 187.7,13.2 188.2,11.1" fill="#FFFFFF"></path>
                <path d="M13.9,12.6 C12.8,12.5 11.4,13.1 11.6,11 C11.8,8.6 13.2,6.9 15.2,6.9 C16.8,6.9 18,8.2 18,9.8 C17.9,11.7 16.6,12.6 13.9,12.6" fill="#FFFFFF"></path>
                <path d="M116.2,12.6 C114.9,12.6 113.5,13.1 113.7,10.9 C113.9,8.5 115.3,6.8 117.3,6.8 C118.9,6.9 120.1,8.1 120,9.7 C120.1,11.8 118.8,12.6 116.2,12.6" fill="#FFFFFF"></path>
            </g>
            <g>
                <g transform="translate(42.000000, 21.000000)">
                    <path d="M54.309151,27.2 C54.309151,41.9 42.009151,54.2 27.409151,53.9 C11.009151,53.6 0.409150984,39.6 0.809150984,26.6 C1.30915098,12 13.009151,0.3 28.109151,0.3 C42.509151,0.4 54.309151,12.5 54.309151,27.2" fill="#FFCE0D"></path>
                    <path d="M27.809151,2 C41.409151,2 52.909151,13.5 52.909151,27.1 C52.909151,40.8 41.209151,52.4 27.509151,52.3 C13.709151,52.2 2.30915098,40.7 2.30915098,26.9 C2.30915098,12.7 15.409151,1 27.809151,2" fill="#FFFFFF"></path>
                    <path d="M27.709151,49.4 C15.509151,49.4 5.30915098,39.7 5.30915098,27.9 C5.20915098,14.8 15.009151,4.6 27.609151,4.6 C39.709151,4.6 49.909151,14.7 49.909151,26.7 C50.009151,39.5 40.309151,49.3 27.709151,49.4" fill="#FFCE0D"></path>
                    <path d="M27.609151,6.3 C38.909151,6.3 48.509151,15.9 48.509151,27.1 C48.409151,38.6 38.909151,48 27.409151,48 C16.109151,48 6.70915098,38.4 6.70915098,27 C6.80915098,15.7 16.309151,6.2 27.609151,6.3" fill="#FFFFFF"></path>
                    <path d="M27.509151,45 C17.509151,45 9.50915098,36.9 9.60915098,26.9 C9.70915098,17.1 17.709151,9.2 27.609151,9.2 C37.709151,9.2 45.709151,17.2 45.709151,27.1 C45.609151,37 37.509151,45 27.509151,45" fill="#FFCE0D"></path>
                    <path d="M27.609151,10.6 C36.609151,10.6 44.109151,18.2 44.109151,27.2 C44.109151,36.1 36.409151,43.6 27.409151,43.6 C18.709151,43.6 10.909151,35.8 11.009151,27.2 C11.209151,18.1 18.709151,10.6 27.609151,10.6" fill="#FFFFFF"></path>
                    <path d="M27.609151,13.6 C35.209151,13.6 41.209151,19.5 41.309151,27 C41.409151,34.4 35.009151,40.7 27.409151,40.6 C20.009151,40.5 13.909151,34.4 13.909151,26.9 C14.009151,19.5 20.009151,13.6 27.609151,13.6" fill="#FFCE0D"></path>
                    <path d="M15.609151,26.8 C15.709151,20.4 21.109151,15 27.609151,15 C34.309151,15 39.809151,20.6 39.709151,27.4 C39.609151,33.7 34.109151,39.3 27.709151,39.3 C21.109151,39.3 15.509151,33.6 15.609151,26.8" fill="#FFFFFF"></path>
                    <path d="M18.509151,27.1 C18.509151,21.8 22.409151,17.8 27.709151,17.9 C32.609151,17.9 36.709151,21.9 36.709151,26.8 C36.709151,32.2 32.809151,36.3 27.709151,36.4 C22.509151,36.4 18.509151,32.4 18.509151,27.1" fill="#FFCE0D"></path>
                    <path d="M35.309151,26.9 C35.409151,31.1 31.709151,34.8 27.609151,34.8 C23.609151,34.8 20.009151,31.2 20.009151,27.2 C20.009151,23 23.509151,19.3 27.609151,19.3 C31.609151,19.3 35.209151,22.9 35.309151,26.9" fill="#FFFFFF"></path>
                </g>
                <g transform="translate(43.000000, 6.000000)">
                    <path d="M36.709151,18.2 C36.709151,28 28.509151,36.2 18.709151,36 C7.70915098,35.8 0.609150984,26.4 0.909150984,17.7 C1.30915098,8 9.10915098,0.1 19.209151,0.2 C28.809151,0.2 36.709151,8.3 36.709151,18.2" fill="#21385C"></path>
                    <path d="M19.009151,1.3 C28.109151,1.3 35.809151,9 35.809151,18.1 C35.809151,27.2 28.009151,35 18.809151,34.9 C9.60915098,34.9 2.00915098,27.1 2.00915098,17.9 C1.90915098,8.5 10.709151,0.6 19.009151,1.3" fill="#FFFFFF"></path>
                    <path d="M18.909151,33 C10.709151,33 3.90915098,26.5 3.90915098,18.6 C3.90915098,9.8 10.409151,3 18.809151,3 C26.909151,3 33.709151,9.8 33.709151,17.8 C33.809151,26.3 27.309151,32.9 18.909151,33" fill="#21385C"></path>
                    <path d="M18.909151,4.2 C26.409151,4.2 32.909151,10.6 32.909151,18.1 C32.909151,25.8 26.509151,32.1 18.809151,32.1 C11.309151,32.1 4.90915098,25.7 5.00915098,18.1 C4.90915098,10.4 11.309151,4.1 18.909151,4.2" fill="#FFFFFF"></path>
                    <path d="M18.809151,30 C12.109151,30 6.80915098,24.6 6.80915098,17.9 C6.80915098,11.3 12.209151,6.1 18.809151,6.1 C25.509151,6.1 30.909151,11.4 30.909151,18.1 C30.909151,24.7 25.509151,30 18.809151,30" fill="#21385C"></path>
                    <path d="M18.909151,7.1 C24.909151,7.1 29.909151,12.2 29.909151,18.2 C29.909151,24.1 24.809151,29.2 18.709151,29.1 C12.909151,29.1 7.70915098,23.9 7.80915098,18.1 C7.90915098,12.1 12.909151,7.1 18.909151,7.1" fill="#FFFFFF"></path>
                    <path d="M18.909151,9.1 C24.009151,9.1 28.009151,13 28.009151,18.1 C28.109151,23.1 23.809151,27.3 18.709151,27.2 C13.709151,27.2 9.70915098,23.1 9.70915098,18 C9.70915098,13 13.809151,9.1 18.909151,9.1" fill="#21385C"></path>
                    <path d="M10.809151,17.9 C10.809151,13.6 14.509151,10 18.809151,10 C23.309151,10 26.909151,13.7 26.909151,18.3 C26.809151,22.5 23.109151,26.2 18.909151,26.3 C14.509151,26.3 10.809151,22.4 10.809151,17.9" fill="#FFFFFF"></path>
                    <path d="M12.809151,18.1 C12.809151,14.5 15.409151,11.9 18.909151,11.9 C22.209151,11.9 24.909151,14.6 24.909151,17.8 C24.909151,21.4 22.309151,24.2 18.909151,24.2 C15.509151,24.3 12.809151,21.6 12.809151,18.1" fill="#21385C"></path>
                    <path d="M24.009151,18 C24.009151,20.8 21.609151,23.3 18.909151,23.3 C16.209151,23.3 13.809151,20.9 13.809151,18.2 C13.809151,15.4 16.209151,12.9 18.909151,12.9 C21.509151,12.9 23.909151,15.3 24.009151,18" fill="#FFFFFF"></path>
                </g>
                <g transform="translate(116.000000, 0.000000)">
                    <path d="M35.809151,18 C35.809151,27.8 27.609151,36 17.809151,35.9 C6.80915098,35.7 -0.290849016,26.3 0.0091509838,17.6 C0.409150984,7.8 8.20915098,0 18.309151,0 C28.009151,0 35.909151,8.1 35.809151,18" fill="#21385C"></path>
                    <path d="M18.109151,1.1 C27.209151,1.1 34.909151,8.8 34.909151,17.9 C34.909151,27 27.109151,34.8 17.909151,34.7 C8.70915098,34.7 1.10915098,26.9 1.10915098,17.7 C1.10915098,8.3 9.90915098,0.5 18.109151,1.1" fill="#FFFFFF"></path>
                    <path d="M18.109151,32.8 C9.90915098,32.8 3.10915098,26.3 3.10915098,18.4 C3.10915098,9.6 9.60915098,2.8 18.009151,2.8 C26.109151,2.8 32.909151,9.6 32.909151,17.6 C32.909151,26.2 26.509151,32.8 18.109151,32.8" fill="#21385C"></path>
                    <path d="M18.009151,4 C25.509151,4 32.009151,10.4 31.909151,17.9 C31.909151,25.6 25.509151,31.9 17.809151,31.9 C10.309151,31.9 3.90915098,25.5 4.00915098,17.9 C4.10915098,10.3 10.409151,4 18.009151,4" fill="#FFFFFF"></path>
                    <path d="M17.909151,29.8 C11.209151,29.8 5.90915098,24.4 5.90915098,17.7 C5.90915098,11.1 11.309151,5.9 17.909151,5.9 C24.609151,5.9 30.009151,11.2 30.009151,17.9 C30.009151,24.5 24.609151,29.8 17.909151,29.8" fill="#21385C"></path>
                    <path d="M18.009151,6.9 C24.009151,6.9 29.009151,12 29.009151,18 C29.009151,23.9 23.909151,29 17.809151,28.9 C12.009151,28.9 6.80915098,23.7 6.90915098,17.9 C7.00915098,11.9 12.009151,6.9 18.009151,6.9" fill="#FFFFFF"></path>
                    <path d="M18.009151,8.9 C23.109151,8.9 27.109151,12.8 27.109151,17.9 C27.209151,22.9 22.909151,27.1 17.809151,27 C12.809151,27 8.80915098,22.9 8.80915098,17.8 C8.90915098,12.8 12.909151,8.9 18.009151,8.9" fill="#21385C"></path>
                    <path d="M10.009151,17.7 C10.009151,13.4 13.709151,9.8 18.009151,9.8 C22.509151,9.8 26.109151,13.5 26.109151,18.1 C26.009151,22.3 22.309151,26 18.109151,26.1 C13.609151,26.1 9.90915098,22.2 10.009151,17.7" fill="#FFFFFF"></path>
                    <path d="M11.909151,17.9 C11.909151,14.3 14.509151,11.7 18.009151,11.7 C21.309151,11.7 24.009151,14.4 24.009151,17.6 C24.009151,21.2 21.409151,24 18.009151,24 C14.609151,24.1 11.909151,21.5 11.909151,17.9" fill="#21385C"></path>
                    <path d="M23.109151,17.8 C23.109151,20.6 20.709151,23.1 18.009151,23.1 C15.309151,23.1 12.909151,20.7 12.909151,18 C12.909151,15.2 15.309151,12.7 18.009151,12.7 C20.609151,12.7 23.109151,15.1 23.109151,17.8" fill="#FFFFFF"></path>
                </g>
                <g transform="translate(56.000000, 65.000000)">
                    <path d="M36.409151,19 C36.409151,28.8 28.209151,37 18.409151,36.8 C7.40915098,36.6 0.309150984,27.2 0.609150984,18.5 C0.909150984,8.7 8.80915098,0.9 18.809151,0.9 C28.509151,1 36.409151,9.1 36.409151,19" fill="#21385C"></path>
                    <path d="M18.709151,2.1 C27.809151,2.1 35.509151,9.8 35.509151,18.9 C35.509151,28 27.709151,35.8 18.509151,35.7 C9.30915098,35.7 1.70915098,27.9 1.70915098,18.7 C1.70915098,9.3 10.409151,1.4 18.709151,2.1" fill="#FFFFFF"></path>
                    <path d="M18.609151,33.8 C10.409151,33.8 3.60915098,27.3 3.60915098,19.4 C3.60915098,10.6 10.109151,3.8 18.509151,3.8 C26.609151,3.8 33.409151,10.6 33.409151,18.6 C33.509151,27.1 27.009151,33.7 18.609151,33.8" fill="#21385C"></path>
                    <path d="M18.609151,5 C26.109151,5 32.609151,11.4 32.509151,18.9 C32.509151,26.6 26.109151,32.9 18.409151,32.9 C10.909151,32.9 4.50915098,26.5 4.60915098,18.9 C4.60915098,11.2 11.009151,4.9 18.609151,5" fill="#FFFFFF"></path>
                    <path d="M18.509151,30.8 C11.809151,30.8 6.50915098,25.4 6.50915098,18.7 C6.50915098,12.1 11.909151,6.9 18.509151,6.9 C25.209151,6.9 30.609151,12.2 30.609151,18.9 C30.609151,25.5 25.209151,30.8 18.509151,30.8" fill="#21385C"></path>
                    <path d="M18.609151,7.9 C24.609151,7.9 29.609151,13 29.609151,19 C29.609151,24.9 24.509151,30 18.409151,29.9 C12.609151,29.9 7.40915098,24.7 7.50915098,18.9 C7.60915098,12.9 12.609151,7.9 18.609151,7.9" fill="#FFFFFF"></path>
                    <path d="M18.609151,9.9 C23.709151,9.9 27.709151,13.8 27.709151,18.9 C27.809151,23.9 23.509151,28.1 18.409151,28 C13.409151,28 9.40915098,23.9 9.40915098,18.8 C9.40915098,13.8 13.509151,9.9 18.609151,9.9" fill="#21385C"></path>
                    <path d="M10.509151,18.7 C10.509151,14.4 14.209151,10.8 18.509151,10.8 C23.009151,10.8 26.609151,14.5 26.609151,19.1 C26.509151,23.3 22.809151,27 18.609151,27.1 C14.209151,27.1 10.509151,23.2 10.509151,18.7" fill="#FFFFFF"></path>
                    <path d="M12.509151,18.9 C12.509151,15.3 15.109151,12.7 18.609151,12.7 C21.909151,12.7 24.609151,15.4 24.609151,18.6 C24.609151,22.2 22.009151,25 18.609151,25 C15.209151,25.1 12.509151,22.4 12.509151,18.9" fill="#21385C"></path>
                    <path d="M23.709151,18.8 C23.709151,21.6 21.309151,24.1 18.609151,24.1 C15.909151,24.1 13.509151,21.7 13.509151,19 C13.509151,16.2 15.909151,13.7 18.609151,13.7 C21.209151,13.7 23.609151,16.1 23.709151,18.8" fill="#FFFFFF"></path>
                </g>
                <g transform="translate(0.000000, 53.000000)">
                    <path d="M35.809151,18.7 C35.809151,28.5 27.609151,36.7 17.809151,36.5 C6.80915098,36.3 -0.290849016,26.9 0.0091509838,18.2 C0.309150984,8.4 8.20915098,0.6 18.209151,0.6 C27.909151,0.7 35.809151,8.8 35.809151,18.7" fill="#21385C"></path>
                    <path d="M18.109151,1.8 C27.209151,1.8 34.909151,9.5 34.909151,18.6 C34.909151,27.7 27.109151,35.5 17.909151,35.4 C8.70915098,35.4 1.10915098,27.6 1.10915098,18.4 C1.10915098,9 9.80915098,1.2 18.109151,1.8" fill="#FFFFFF"></path>
                    <path d="M18.009151,33.5 C9.80915098,33.5 3.00915098,27 3.00915098,19.1 C3.00915098,10.3 9.50915098,3.5 17.909151,3.5 C26.009151,3.5 32.809151,10.3 32.809151,18.3 C32.909151,26.9 26.409151,33.4 18.009151,33.5" fill="#21385C"></path>
                    <path d="M18.009151,4.7 C25.509151,4.7 32.009151,11.1 32.009151,18.6 C32.009151,26.3 25.609151,32.6 17.909151,32.6 C10.409151,32.6 4.00915098,26.2 4.10915098,18.6 C4.00915098,10.9 10.409151,4.7 18.009151,4.7" fill="#FFFFFF"></path>
                    <path d="M17.909151,30.5 C11.209151,30.5 5.90915098,25.1 5.90915098,18.4 C5.90915098,11.8 11.309151,6.6 17.909151,6.6 C24.609151,6.6 30.009151,11.9 30.009151,18.6 C30.009151,25.2 24.609151,30.5 17.909151,30.5" fill="#21385C"></path>
                    <path d="M18.009151,7.6 C24.009151,7.6 29.009151,12.7 29.009151,18.7 C29.009151,24.6 23.909151,29.7 17.809151,29.6 C12.009151,29.6 6.80915098,24.4 6.90915098,18.6 C7.00915098,12.6 12.009151,7.6 18.009151,7.6" fill="#FFFFFF"></path>
                    <path d="M18.009151,9.6 C23.109151,9.6 27.109151,13.5 27.109151,18.6 C27.209151,23.6 22.909151,27.8 17.809151,27.7 C12.809151,27.7 8.80915098,23.6 8.80915098,18.5 C8.80915098,13.5 12.909151,9.6 18.009151,9.6" fill="#21385C"></path>
                    <path d="M9.90915098,18.4 C9.90915098,14.1 13.609151,10.5 17.909151,10.5 C22.409151,10.5 26.009151,14.2 26.009151,18.8 C25.909151,23 22.209151,26.7 18.009151,26.8 C13.609151,26.8 9.90915098,22.9 9.90915098,18.4" fill="#FFFFFF"></path>
                    <path d="M11.909151,18.6 C11.909151,15 14.509151,12.4 18.009151,12.4 C21.309151,12.4 24.009151,15.1 24.009151,18.3 C24.009151,21.9 21.409151,24.7 18.009151,24.7 C14.609151,24.8 11.909151,22.1 11.909151,18.6" fill="#21385C"></path>
                    <path d="M23.109151,18.5 C23.109151,21.3 20.709151,23.8 18.009151,23.8 C15.309151,23.8 12.909151,21.4 12.909151,18.7 C12.909151,15.9 15.309151,13.4 18.009151,13.4 C20.609151,13.4 23.009151,15.8 23.109151,18.5" fill="#FFFFFF"></path>
                </g>
                <g transform="translate(1.000000, 101.000000)">
                    <path d="M35.909151,18 C35.909151,27.8 27.709151,36 17.909151,35.9 C6.90915098,35.7 -0.190849016,26.3 0.109150984,17.6 C0.409150984,7.8 8.30915098,0 18.309151,0 C28.109151,0.1 36.009151,8.2 35.909151,18" fill="#21385C"></path>
                    <path d="M18.209151,1.1 C27.309151,1.1 35.009151,8.8 35.009151,17.9 C35.009151,27 27.209151,34.8 18.009151,34.7 C8.80915098,34.7 1.20915098,26.9 1.20915098,17.7 C1.20915098,8.3 10.009151,0.5 18.209151,1.1" fill="#FFFFFF"></path>
                    <path d="M18.209151,32.8 C10.009151,32.8 3.20915098,26.3 3.20915098,18.4 C3.20915098,9.6 9.70915098,2.8 18.109151,2.8 C26.209151,2.8 33.009151,9.6 33.009151,17.6 C33.009151,26.2 26.609151,32.8 18.209151,32.8" fill="#21385C"></path>
                    <path d="M18.109151,4 C25.609151,4 32.109151,10.4 32.109151,17.9 C32.109151,25.6 25.709151,31.9 18.009151,31.9 C10.509151,31.9 4.10915098,25.5 4.20915098,17.9 C4.20915098,10.3 10.509151,4 18.109151,4" fill="#FFFFFF"></path>
                    <path d="M18.009151,29.9 C11.309151,29.9 6.00915098,24.5 6.00915098,17.8 C6.00915098,11.2 11.409151,6 18.009151,6 C24.709151,6 30.109151,11.3 30.109151,18 C30.109151,24.6 24.709151,29.9 18.009151,29.9" fill="#21385C"></path>
                    <path d="M18.109151,6.9 C24.109151,6.9 29.109151,12 29.109151,18 C29.109151,23.9 24.009151,29 17.909151,28.9 C12.109151,28.9 6.90915098,23.7 7.00915098,17.9 C7.10915098,11.9 12.109151,6.9 18.109151,6.9" fill="#FFFFFF"></path>
                    <path d="M18.109151,8.9 C23.209151,8.9 27.209151,12.8 27.209151,17.9 C27.309151,22.9 23.009151,27.1 17.909151,27 C12.909151,27 8.90915098,22.9 8.90915098,17.8 C9.00915098,12.9 13.009151,8.9 18.109151,8.9" fill="#21385C"></path>
                    <path d="M10.109151,17.7 C10.109151,13.4 13.809151,9.8 18.109151,9.8 C22.609151,9.8 26.209151,13.5 26.209151,18.1 C26.109151,22.3 22.409151,26 18.209151,26.1 C13.709151,26.1 10.009151,22.3 10.109151,17.7" fill="#FFFFFF"></path>
                    <path d="M12.009151,17.9 C12.009151,14.3 14.609151,11.7 18.109151,11.7 C21.409151,11.7 24.109151,14.4 24.109151,17.6 C24.109151,21.2 21.509151,24 18.109151,24 C14.709151,24.1 12.009151,21.5 12.009151,17.9" fill="#21385C"></path>
                    <path d="M23.209151,17.8 C23.209151,20.6 20.809151,23.1 18.109151,23.1 C15.409151,23.1 13.009151,20.7 13.009151,18 C13.009151,15.2 15.409151,12.7 18.109151,12.7 C20.709151,12.7 23.209151,15.1 23.209151,17.8" fill="#FFFFFF"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}

export default Logo
