import React from 'react'

const CardBg = () => {
  return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 189.9 95.4"
            style={{ enableBackground: 'new 0 0 189.9 95.4' }}
            xmlSpace="preserve"
        >
            <style type="text/css">
                {`.st0{fill:#141827;}
      .st1{fill:none;stroke:#141827;stroke-width:6;}`}
            </style>
            <g>
                <g transform="translate(272.967033 154.285714)">
                    <path
                        className="st0"
                        d="M-157.4-86.1l-47.1-67.2l-64.6,93h182l-45.5-56.2L-157.4-86.1z M-171.7-66.6H-257l52.5-75.4l52.8,75.4H-171.7z M-144-66.6l-9.9-13.8l21.3-26.1l32.4,39.9L-144-66.6z"
                    />
                    <circle className="st1" cx="-122.7" cy="-137.5" r="11.9"/>
                </g>
            </g>
        </svg>
  )
}

export default CardBg
