import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store/store'
import { PersistGate } from 'redux-persist/integration/react'
// import { EventId } from "./ContextAPI/EventId";
// import { EventIdContext } from "./ContextAPI/EventId";

const language = 'de'

const loadJSON = (file, callback) => {
  const xobj = new XMLHttpRequest()
  xobj.overrideMimeType('application/json')
  if (file) {
    xobj.open('GET', file, true)
  }

  xobj.onreadystatechange = () => {
    if (xobj.readyState === 4 && xobj.status === 200) {
      callback(JSON.parse(xobj.responseText))
    }
  }
  xobj.send(null)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

loadJSON('/translations.json', data => {
  if (language === 'en ') {
    localStorage.setItem('en', 'en')
  } else {
    localStorage.removeItem('en')
  }
  root.render(
        // <EventId>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <IntlProvider locale="de" messages={data[language]}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </IntlProvider>
            </PersistGate>
        </Provider>
        // </EventId>
  )
})
