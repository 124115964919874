import React from 'react'
import { useNavigate } from 'react-router-dom'

// Intl
import { FormattedMessage } from 'react-intl'
import { AsqBtn } from '../../../assets/muiStyles/Button-style'

// Redux
import { useGlobalValue } from '../../../hooks/redux/reduxHooks'

const AsqBtnComponent = ({ id, referrer }) => {
  const navigate = useNavigate()
  const { slug, eventId } = useGlobalValue()

  return (
        <AsqBtn
            onClick={() =>
              navigate(`/events/${eventId}/${slug}/questions/`, {
                state: { id, referrer }
              })
            }
        >
            <p className="mT2px"><FormattedMessage id="ask-a-question" defaultMessage="Ask the speaker"/>
            </p>
        </AsqBtn>
  )
}

export default AsqBtnComponent
