import React from 'react'
import ReminderModal from '../../components/ReminderModal'

const ReminderPage = () => {
  return (
        <div className='container'>
            <ReminderModal/>
        </div>
  )
}

export default ReminderPage
