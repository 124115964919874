import React from 'react'
import noAvatar from '../../images/noAvatar.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Avatar = ({ url = '', status_online = false, name, id = false }) => {
  // hooks
  const navigate = useNavigate()
  // reducer
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const slug = useSelector(state => state.globalValueReducer.slug)

  return (
        <div

            onClick={() => {
              if (id) {
                navigate(`/events/${eventId}/${slug}/participants/profile/`, { state: { profileId: id } })
              }
            }}
            className='avatar-participants cursor-pointer avatar-status'
        >
            <img
                src={url || noAvatar}
                alt={name}
            />
            {/* {status_online */}
            {/*  ? <p className="online-circle" */}
            {/*    /> */}
            {/*  : <p className="offline-circle" */}
            {/*    />} */}
        </div>
  )
}
export default Avatar
