import React from 'react'

const FacebookShareIcon = () => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M16 8C16 3.584 12.416 0 8 0C3.584 0 0 3.584 0 8C0 11.872 2.752 15.096 6.4 15.84V10.4H4.8V8H6.4V6C6.4 4.456 7.656 3.2 9.2 3.2H11.2V5.6H9.6C9.16 5.6 8.8 5.96 8.8 6.4V8H11.2V10.4H8.8V15.96C12.84 15.56 16 12.152 16 8Z"
                fill="#282828"/>
        </svg>
  )
}

export default FacebookShareIcon
