import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

// src
import './HomePage.scss'
import { useGetAboutUsQuery, useGetBannerEventsQuery, useGetNewsQuery } from '../../redux/api/homePageApi/homePageApi'
// import { EventIdContext } from "../ContextAPI/EventId";
// import AboutUs from './AboutUs/AboutUs'
import Banner from './Banner/Banner'
import RecentEvents from './RecentEvents/RecentEvents'
import UpcomingEvents from './UpcomingEvents/UpcomingEvents'
import Card from '../../components/Card/Card'
import ReminderModal from '../../components/ReminderModal'
import { useSelector } from 'react-redux'
import bgCathdedral from '../../images/cathedral.png'

const HomePage = () => {
  // api
  const { data, isLoading, isFetching, refetch } = useGetBannerEventsQuery()
  const { isOpenReminderModal } = useSelector((state) => state.eventSliceReducer)
  const { data: aboutUs } = useGetAboutUsQuery()
  const { data: news } = useGetNewsQuery()

  const HtmlRenderer = ({ data }) => {
    return data?.map((el, i) => {
      // eslint-disable-next-line react/jsx-key
      return <div key={i} dangerouslySetInnerHTML={{ __html: el.value }}
                        className='html-renderer-style'
            />
    })
  }

  const getCardData = (data) => {
    return ''
  }

  const bannerEvent = data ?? {}

  return (
        <>
            {isLoading && isFetching && <CircularProgress className='circularProgress'/>}
            {!isLoading && !isFetching && bannerEvent && Object.keys(bannerEvent)?.length > 0 && (
                <>
                    <Banner bannerEvent={bannerEvent} refetch={refetch}/>
                    {isOpenReminderModal && <ReminderModal/>}

                    {!!news?.length && news?.map((el, i) => {
                      return <Card key={el.id}
                            // type={i === 0 ? 'News' : ''}
                                     type={i === 0 ? 'Aktuelles' : ''}
                                     index={i}
                                     description={<HtmlRenderer data={el?.body}/>}
                                     isReverse={false}
                                     image={el?.image?.meta?.download_url}
                                     subtitle='info'
                                     title={el?.title}/>
                    })}
                    <UpcomingEvents/>
                    <RecentEvents/>
                    <div style={{ marginBottom: '165px', marginTop: '165px' }}>
                        {!!aboutUs?.length && aboutUs?.map((el, i) => {
                          return <Card key={el.id}
                                // type={i === 0 ? 'Learn more' : ''}
                                         type={i === 0 ? 'Weitere Informationen' : ''}
                                         id='learn-more'
                                         linkToSite={Array.isArray(getCardData(el?.body)) && getCardData(el?.body)[0]?.hrefValue}
                                         image={el?.image?.meta?.download_url}
                                         style={{ paddingTop: i === 0 ? '0' : '20px' }}
                                         description={<HtmlRenderer
                                             data={el?.body}/>}
                                // isReverse={true} poster={bgCathdedral} subtitle='cathedral website'
                                         isReverse={true} poster={bgCathdedral} subtitle='Website der Kathedrale'
                                         title={el?.title}/>
                        })}
                    </div>
                </>
            )}
        </>
  )
}

export default HomePage
