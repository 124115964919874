import { defaultApi } from '../splitApi'

export const homePageApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getBannerEvents: (build.query({
      query: () => 'base/events/highlight/',
      transformResponse: (res) => {
        return res?.items
      }
    })),
    getAllEvents: (build.query({
      async queryFn ({ eventId, offset, limit, searchValue, from, to }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`/base/events/${!limit ? '?limit=9' : `?limit=${limit}`}${!eventId ? '' : `&event_type=${eventId}`}${!searchValue ? '' : `&search=${searchValue}`}${!offset ? '&offset=0' : `&offset=${offset}`}${from ? `&date_from=${from}` : ''}${to ? `&date_to=${to}` : ''}`)

          const length = events.data.items.length
          const newArr = []
          for (let i = 0; i < length; i++) {
            const id = events?.data.items[i]?.id
            const spData = (await fetchWithBQ(`base/events/${id}`))?.data?.speakers
            newArr.push({ ...events.data.items[i], speakers: spData })
          }

          const result = {
            ...events,
            data: {
              items: newArr,
              meta: events.data.meta
            }
          }

          return { data: result }
        } catch (e) {
          return { error: e?.message }
        }
      }
    })),
    getRecentEvents: (build.query({
      async queryFn ({ offset, limit }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`base/events/recent${!limit ? '?limit=1000' : `?limit=${limit}`}${!offset ? '&offset=0' : `&offset=${offset}`}`)

          return { data: events }
        } catch (e) {
          return { error: e?.message }
        }
      }
    })),
    getFilterRecent: (build.query({
      async queryFn ({ eventId, offset, limit, searchValue, from, to }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`/base/events/recent${!limit ? '?limit=9' : `?limit=${limit}`}${!eventId ? '' : `&event_type=${eventId}`}${!searchValue ? '' : `&search=${searchValue}`}${!offset ? '&offset=0' : `&offset=${offset}`}${from ? `&date_from=${from}` : ''}${to ? `&date_to=${to}` : ''}`)

          const length = events.data.items.length
          const newArr = []
          for (let i = 0; i < length; i++) {
            const id = events?.data.items[i]?.id
            const spData = (await fetchWithBQ(`base/events/${id}`))?.data?.speakers
            newArr.push({ ...events.data.items[i], speakers: spData })
          }

          const result = {
            ...events,
            data: {
              items: newArr,
              meta: events.data.meta
            }
          }

          return { data: result }
        } catch (e) {
          return { error: e?.message }
        }
      }
    })),
    getUpcomingEvents: (build.query({
      async queryFn ({ offset, limit }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`base/events/upcoming/?offset=${offset}&limit=${limit}`)
          const length = events.data.meta.total_count
          const newArr = []
          for (let i = 0; i < length; i++) {
            const id = events?.data.items[i]?.id
            const spData = await fetchWithBQ(`base/events/${id}/people/speakers/`)
            newArr.push({ event: events.data.items[i], speakers: spData })
          }
          return { data: newArr }
        } catch (e) {
          return { error: e?.message }
        }
      }
    })),
    getFilterUpcoming: (build.query({
      async queryFn ({ eventId, offset, limit, searchValue, from, to }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`/base/events/upcoming${!limit ? '?limit=9' : `?limit=${limit}`}${!eventId ? '' : `&event_type=${eventId}`}${!searchValue ? '' : `&search=${searchValue}`}${!offset ? '&offset=0' : `&offset=${offset}`}${from ? `&date_from=${from}` : ''}${to ? `&date_to=${to}` : ''}`)
          const length = events.data.items.length
          const newArr = []
          for (let i = 0; i < length; i++) {
            const id = events?.data.items[i]?.id
            const spData = (await fetchWithBQ(`base/events/${id}`))?.data?.speakers
            newArr.push({ ...events.data.items[i], speakers: spData })
          }

          const result = {
            ...events,
            data: {
              items: newArr,
              meta: events.data.meta
            }
          }

          return { data: result }
        } catch (e) {
          return { error: e?.message }
        }
      }

    })),
    getEventTypes: (build.query({
      query: () => 'base/choices/event_types/',
      transformResponse: (res) => {
        return res?.items
      }
    })),
    getSocialLinks: (build.query({
      query: () => '/base/choices/event_social_share/',
      transformResponse: (res) => {
        return res?.items
      }
    })),
    getAboutUs: (build.query({
      query: () => 'base/about_us/',
      transformResponse: (res) => {
        return res?.items
      }
    })),
    getNews: (build.query({
      query: () => 'base/news/',
      transformResponse: (res) => {
        return res?.items
      }
    })),
    getEventDesc: build.query({
      query: (eventId) => `base/events/${eventId}/`,
      keepUnusedDataFor: 0
    }),
    eventStream: build.query({
      query: (eventId) => `base/events/${eventId}/streams/`,
      keepUnusedDataFor: 0

    }),
    getLivePoll: build.query({
      query: (eventId) => `base/events/${eventId}/polls/live/`
    }),
    getEventSpeakers: build.query(({
      query: (eventId) => `base/events/${eventId}/people/speakers/`
    })),
    createReminder: build.mutation({
      query: (newReminder, id) => ({
        url: `/base/events/${newReminder.id}/reminders/`, // Adjust the URL as needed
        method: 'POST',
        body: { e_mail_address: newReminder.e_mail_address }
      })
    })
  })
})
export const {
  useGetBannerEventsQuery,
  useGetAllEventsQuery,
  useGetRecentEventsQuery,
  useGetFilterRecentQuery,
  useGetEventTypesQuery,
  useGetFilterUpcomingQuery,
  useGetUpcomingEventsQuery,
  useGetAboutUsQuery,
  useGetNewsQuery,
  useGetEventDescQuery,
  useEventStreamQuery,
  useGetLivePollQuery,
  useCreateReminderMutation,
  useGetEventSpeakersQuery,
  useGetSocialLinksQuery
} = homePageApi
