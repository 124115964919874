import React, { useEffect, useState } from 'react'
import './ReminderModal.scss'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox } from '@mui/material'
import LetterIcon from '../../images/icons/LetterIcon'
import { useCreateReminderMutation } from '../../redux/api/homePageApi/homePageApi'
import { useDispatch, useSelector } from 'react-redux'
import { changeOpenReminderModal, onChangeReminderId } from '../../redux/slices/eventsSlice/eventsSlice'
import CloseIcon from '../../images/icons/CloseIcon'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'

const ReminderModal = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [checkboxChecked, setCheckboxChecked] = useState({ isRead: false, notifications: false })
  const [success, setSuccess] = useState(false)
  const reminderId = useSelector((state) => state.eventSliceReducer.reminderId)
  const [createReminder] = useCreateReminderMutation()

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const [errorMessages, setErrorMessages] = useState({
    checkbox: false,
    email: false
  })

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    if (email && checkboxChecked.isRead) {
      await createReminder({
        e_mail_address: email,
        id: reminderId
      }).finally(() => dispatch(onChangeReminderId(null)))
      setSuccess(true)
    } else {
      setErrorMessages(() => {
        return {
          checkbox: !checkboxChecked.isRead,
          email: !emailPattern.test(email)
        }
      })
    }
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.classList.add('hide-scroll')

    return () => {
      body.classList.remove('hide-scroll')
    }
  }, [])

  return (
        <div className='reminder-modal-wrap'>
            {!success ? (
                <div className='reminder'>
                    <Box className='reminder__close-icon'
                         onClick={() => dispatch(changeOpenReminderModal(false))}><CloseIcon/></Box>
                    <h1 className='reminder__title'><FormattedMessage id="Set a reminder" defaultMessage="Set a reminder"/></h1>
                    {/* <h1 className='reminder__title'>Erinnerung aktivieren</h1> */}
                    <p className='reminder__subtitle'>
                      <FormattedMessage id="Get notified before the event starts so you don't miss it." defaultMessage="Get notified before the event starts so you don't miss it."/>
                    </p>
                    <form onSubmit={(e) => handleSubmitForm(e)}>
                        <input
                            type="email"
                            className='reminder__email-input'
                            placeholder='E-Mail Adresse'
                            // placeholder='E-Mail Adresse'
                            value={email}
                            onChange={(e) => {
                              setEmail(e?.target?.value)
                            }}
                        />
                        {errorMessages.email &&
                            <span style={{ color: 'red' }}><FormattedMessage id="Enter a valid email" defaultMessage="Enter a valid email"/></span>}
                            {/* <span style={{ color: 'red' }}>Geben Sie eine gültige E-Mail ein</span>} */}
                        <div className='reminder__checkbox-wrap'>
                            <FormControlLabel
                                control={<Checkbox checked={checkboxChecked.isRead}
                                                   onChange={() => {
                                                     setCheckboxChecked(prev => {
                                                       return {
                                                         ...prev,
                                                         isRead: !prev.isRead
                                                       }
                                                     })
                                                   }}/>
                                }
                                // label="I have read and agree to the privacy policy and terms of service."
                                label="Ich habe die Datenschutzrichtlinie und die Nutzungsbedingungen gelesen und stimme ihnen zu."
                            />
                            {errorMessages.checkbox && !checkboxChecked.isRead &&
                                <span style={{ color: 'red' }}><FormattedMessage id="This field is required." defaultMessage="This field is required." /></span>}
                                {/* <span style={{ color: 'red' }}>Dieses Feld ist erforderlich.</span>} */}
                            <FormControlLabel
                                control={<Checkbox checked={checkboxChecked.notifications}
                                                   onChange={() => setCheckboxChecked(prev => {
                                                     return {
                                                       ...prev,
                                                       notifications: !prev.notifications
                                                     }
                                                   })}/>}
                                // label="I would like to receive notifications about the upcoming event."
                                label="Ich möchte Benachrichtigungen über die kommende Veranstaltung erhalten."
                            />
                        </div>

                         <button className='reminder__success-button' type='submit'><FormattedMessage id="Notify me" defaultMessage="Notify me" /></button>
                        {/* <button className='reminder__success-button' type='submit'>Aktivieren</button> */}
                    </form>
                </div>

            ) : (
                <div className='reminder__success-container'>
                    <div className='reminder__letter-icon-wrap'>
                        <LetterIcon/>
                    </div>
                    {/* <h1 className='reminder__title'>All set!</h1> */}
                    <h1 className='reminder__title'>Fertig!</h1>
                    <p className='reminder__subtitle'>
                      <FormattedMessage id="You will be notified before the event starts." defaultMessage="You will be notified before the event starts." />
                        {/* Sie werden vor Beginn der Veranstaltung benachrichtigt. */}
                    </p>
                    <button className='reminder__success-button' onClick={() => {
                      dispatch(changeOpenReminderModal(false))
                    // }}>Done
                    }}>OK
                    </button>
                </div>
            )}
        </div>
  )
}

export default ReminderModal
