import React from 'react'

const LetterIcon = () => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="212" height="192" viewBox="0 0 212 192" fill="none">
            <g clipPath="url(#clip0_3356_14418)">
                <path d="M11.2515 175.57L197.585 174.153L196.647 63.7359L10.3135 65.1529L11.2515 175.57Z"
                      fill="#3C64BA"/>
                <path opacity="0.1" d="M11.2515 175.57L197.585 174.153L196.647 63.7359L10.3135 65.1529L11.2515 175.57Z"
                      fill="black"/>
                <path opacity="0.1" d="M103.997 124.669L10.316 65.1483L196.646 63.735L103.997 124.669Z" fill="#263238"/>
                <path
                    d="M91.3896 11.8349L10.3159 65.1484L196.646 63.7273L114.675 11.6528C107.559 7.12801 98.4347 7.19852 91.3903 11.827L91.3896 11.8349Z"
                    fill="#3C64BA"/>
                <path opacity="0.2"
                      d="M189.725 63.7852L111.842 14.3015C106.47 10.891 99.5815 10.9439 94.2722 14.4387L17.2372 65.0984L189.725 63.7852Z"
                      fill="#263238"/>
                <path
                    d="M155.717 59.4429L180.139 68.2854L180.194 74.5549L103.997 124.669L26.9517 75.7196L26.8412 63.1963L155.717 59.4429Z"
                    fill="white"/>
                <path d="M155.717 59.4429L159.296 72.8907L180.139 68.2854" fill="#E0E0E0"/>
                <path d="M155.717 59.4429L157.201 70.4037L180.139 68.2854" fill="#F0F0F0"/>
                <path
                    d="M119.798 114.26L197.587 174.153L11.2567 175.574L88.0191 114.505C97.2832 107.134 110.407 107.034 119.798 114.26Z"
                    fill="#3C64BA"/>
            </g>
            <defs>
                <clipPath id="clip0_3356_14418">
                    <rect width="196.105" height="174.76" fill="white"
                          transform="translate(16 0.555908) rotate(5.02885)"/>
                </clipPath>
            </defs>
        </svg>
  )
}

export default LetterIcon
