import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import HomePage from './pages/HomePage/HomePage'
import React, { Suspense, useEffect } from 'react'
import ReactGA from 'react-ga'
import { TailSpin } from 'react-loader-spinner'

// src
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import InboxPage from './pages/InboxPage/InboxPage'
import LivePollPage from './pages/LivePollPage/LivePollPage'
import AskQuestion from './pages/AskQuestionPage/AskQuestion'
import Participants from './pages/ParticipantsPage/Participants'
import EventAgenda from './pages/EventAgendaPage/EventAgenda'
import ParticipantProfile from './pages/ParticipantsPage/ParticipantProfile/ParticipantProfile'
import ForgetPassword from './pages/SignUpPage/ForgetPassword'
import setAuthToken from './auth/SetAuthToken'
import ProtectedRoute from './auth/ProtectedRoute'
import ResetPassword from './pages/SignUpPage/ResetPassword'
import PrivateChat from './pages/InboxPage/Chats/PrivateChat/PrivateChat'
import Exhibition from './pages/Exhibition/Exhibition'
import Posters from './pages/Posters/Posters'
import EditingProfilePage from './pages/EditingProfilePage/EditingProfilePage'
import EventAgendaDetail from './pages/EventAgendaPage/EventAgendaDetail/EventAgendaDetail'
import PublicChat from './pages/InboxPage/Chats/PublicChat/PublicChat'
import Register from './pages/SignUpPage/Register'
import { useSelector } from 'react-redux'
import BaseLayout from './components/BaseLayout'
import Event from './pages/Event'
import EventsPage from './pages/EventsPage'
import UpcomingPage from './pages/UpcomingEvents'
import ReminderPage from './pages/ReminderPage'
import RecentEvents from './pages/RecentEvents'

function App () {
  const TRACKING_ID = 'UA-55102476-3'
  ReactGA.initialize(TRACKING_ID)
  // const navigate = useNavigate()
  const token = useSelector(state => state.authReducer.token)

  if (token) {
    setAuthToken(token)
  } else {
    // if (window.location.pathname.includes('/events')) {
    //   navigate('/')
    // }
  }
  // const [sendBird, setSendBird] = useState(null)

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (<>
            {token ? (
                <Layout>
                    <Suspense
                        fallback={<TailSpin color="#00BFFF" height={80} width={80}/>}
                    />
                    <Routes>
                        <Route
                            path="/events/:id/:slug/inbox/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route path="/events/:id/:slug/inbox/" element={<InboxPage/>}/>
                        </Route>
                        <Route
                            path="/events/:id/:slug/polls/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route path="/events/:id/:slug/polls/" element={<LivePollPage/>}/>
                        </Route>
                        <Route
                            path="/events/:id/:slug/questions/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/questions/"
                                element={<AskQuestion/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/participants/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/participants/"
                                element={<Participants/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/exhibition/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/exhibition/"
                                element={<Exhibition/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/posters/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/posters/"
                                element={<Posters/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/posters/:postersId/details/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/posters/:postersId/details/"
                                element={<Posters/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/:agendaId/video/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/:agendaId/video/"
                                element={<EventAgendaDetail/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/participants/profile/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/participants/profile/"
                                element={<ParticipantProfile/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/participants/chat/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/participants/chat/:userId"
                                element={<PrivateChat/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/hotline/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/hotline/"
                                element={<PublicChat/>}
                            />
                        </Route>
                        <Route
                            path="/events/:id/:slug/agenda/"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route path="/events/:id/:slug/agenda/" element={<EventAgenda/>}/>
                        </Route>
                        <Route
                            path="/events/:id/:slug/agenda/:agendaId/:agendaTitle"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route
                                path="/events/:id/:slug/agenda/:agendaId/:agendaTitle"
                                element={<EventAgendaDetail/>}
                            />
                        </Route>
                        {/* <Route */}
                        {/*    path="/user/complete-profile/:key" */}
                        {/*    element={<ProtectedRoute redirect="/sign-in"/>} */}
                        {/* > */}
                        {/*    <Route path="/user/complete-profile/:key" element={<SignUpPage/>}/> */}
                        {/* </Route> */}
                        <Route
                            path="/events/participants/chat/:key"
                            element={<ProtectedRoute redirect="/sign-in"/>}
                        >
                            <Route path="/events/participants/chat/:key" element={<SignUpPage/>}/>
                        </Route>

                        <Route path="/user/editing-profile" element={<EditingProfilePage/>}/>

                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/sign-in" element={<SignInPage/>}/>
                        <Route path="/sign-up" element={<SignUpPage/>}/>
                        <Route path="/user/complete-profile/:key" element={<SignUpPage/>}/>
                        <Route path="/password/forgot" element={<ForgetPassword/>}/>
                        <Route path="/user/register" element={<Register/>}/>
                        <Route path="/password/reset/key/:key" element={<ResetPassword/>}/>
                        <Route path="*" element={<>No Page Found</>}/>
                    </Routes>
                </Layout>) : (
                <BaseLayout>
                    <Suspense
                        fallback={<TailSpin color="#00BFFF" height={80} width={80}/>}
                    />
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/events/:id/:slug/" element={<Event/>}/>
                        <Route path="/recent_events" element={<RecentEvents/>}/>
                        <Route path="/events" element={<EventsPage/>}/>
                        <Route path="/upcoming_events" element={<UpcomingPage/>}/>
                        <Route path="/reminder" element={<ReminderPage/>}/>
                    </Routes>
                </BaseLayout>
            )}

        </>

  )
}

export default App
