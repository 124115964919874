import React from 'react'

import './SpeakerCard.scss'

const SpeakerCard = ({ speaker, key }) => {
  return (
        <div className='speaker-card' key={key}>
            <img src={speaker?.avatar?.download_url} alt="avatar" className='speaker-card__avatar'/>
            <div className='speaker-card__description'>
                <p className='speaker-card__name'>{speaker?.full_name}</p>
                <p className='speaker-card__user-type'>{speaker?.title}</p>
            </div>
        </div>
  )
}

export default SpeakerCard
