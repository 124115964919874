import React from 'react'

const CloseIcon = ({ color }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <g clipPath="url(#clip0_3170_988)">
                <path
                    d="M27.4501 8.56508C26.8651 7.98008 25.9201 7.98008 25.3351 8.56508L18.0001 15.8851L10.6651 8.55008C10.0801 7.96508 9.13508 7.96508 8.55008 8.55008C7.96508 9.13508 7.96508 10.0801 8.55008 10.6651L15.8851 18.0001L8.55008 25.3351C7.96508 25.9201 7.96508 26.8651 8.55008 27.4501C9.13508 28.0351 10.0801 28.0351 10.6651 27.4501L18.0001 20.1151L25.3351 27.4501C25.9201 28.0351 26.8651 28.0351 27.4501 27.4501C28.0351 26.8651 28.0351 25.9201 27.4501 25.3351L20.1151 18.0001L27.4501 10.6651C28.0201 10.0951 28.0201 9.13508 27.4501 8.56508Z"
                    fill={color || '#939393'}/>
            </g>
            <defs>
                <clipPath id="clip0_3170_988">
                    <rect width="36" height="36" fill="white"/>
                </clipPath>
            </defs>
        </svg>
  )
}

export default CloseIcon
