export const formatDateString = (dateString) => {
  const date = new Date(dateString)

  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const formatDateRange = (dateRange) => {
  const [startDateString, endDateString] = dateRange.split(' - ')

  const formattedStartDate = formatDateString(startDateString)
  const formattedEndDate = formatDateString(endDateString)

  return `${formattedStartDate} - ${formattedEndDate}`
}
