import React, { useEffect, useState } from 'react'

// src
import './Banner.scss'
import { useNavigate } from 'react-router-dom'
import { getMonth } from '../../../parser'
import { useGetIsStartedEvent } from '../../../components/BaseLayout/BaseLayout'
import ReactPlayer from 'react-player'
import bg from '../../../images/temp-bg-banner-photo.jpg'
import CloseIcon from '../../../images/icons/CloseIcon'
import { FormattedMessage } from 'react-intl'
import { useWindowSize } from '../../../hooks/useWindowSize'

const Banner = ({ bannerEvent, refetch }) => {
  // hooks
  const navigate = useNavigate()
  // const dispatch = useDispatch()

  const [livebtn, setLivebtn] = useState(0)
  const [isLiveEvent, setIsLiveEvent] = useState(false)
  const [isEventRunning, setIsEventRunning] = useState(false)
  const [isOverlayOpen, setIsOverlayOpen] = useState(true)

  const { isStartedEvent, setIsStartedEvent, isCloseOverlay } = useGetIsStartedEvent()

  const { width, height } = useWindowSize()

  useEffect(() => {
    const items = bannerEvent
    try {
      const currentTime = new Date().getTime()
      const begin = new Date(items[0]?.schedule_begin?.substring(0, 16))?.getTime()
      const end = new Date(items[0]?.schedule_end?.substring(0, 16))?.getTime()
      if (currentTime > begin && currentTime < end) {
        setLivebtn(1)
      } else if (currentTime < begin) {
        setLivebtn(2)
      } else {
        setLivebtn(3)
      }
    } catch (e) {
      setLivebtn(3)
    }
  }, [bannerEvent])

  const formatStreamedDate = function (inputTimeStr) {
    const inputDate = new Date(inputTimeStr).toUTCString()
    // const months = [
    //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    // ]

    // return `Streamed on: ${months[inputDate.getUTCMonth()]} ${inputDate.getUTCDate()}, ${inputDate.getUTCFullYear()}`
    if (isLiveEvent && isEventRunning) {
      return 'Streaming jetzt....'
    } else if (isLiveEvent) {
      return `Wurde gestreamed am:  ${getMonth(inputDate)} ${inputDate.split(' ')[1]}, ${inputDate.split(' ')[3]}, ${inputTimeStr.substring(11, 19)}`
    }
    return `Wird gestreamed am:  ${getMonth(inputDate)} ${inputDate.split(' ')[1]}, ${inputDate.split(' ')[3]}, ${inputTimeStr.substring(11, 19)}`
  }

  useEffect(() => {
    if (!bannerEvent) return
    const currentDate = new Date()
    const date1 = new Date(currentDate)
    const date2 = new Date(bannerEvent[0]?.schedule_begin)
    const utcDateCurrent = new Date(date1.toISOString())
    const utcDateStart = new Date(date2.toISOString())
    const durationInMinutes = 3 // how many minutes before the real start should it already be showing the stream
    utcDateStart.setMinutes(utcDateStart.getMinutes() - durationInMinutes)
    const isLiveEvent = utcDateCurrent > utcDateStart
    //  console.log('BANNER: start date is: ' + utcDateStart + ' isLiveEvent: ' + isLiveEvent)

    if (bannerEvent[0]?.schedule_end) {
      const date3 = new Date(bannerEvent[0]?.schedule_end)
      const utcDateEnd = new Date(date3.toISOString())
      const isEventRunning = utcDateEnd > utcDateCurrent && utcDateCurrent > utcDateStart
      //  console.log(' isEventRunning: ' + isEventRunning + ' utcDateEnd: ' + utcDateEnd + 'utcDateCurrent: ' + utcDateCurrent + 'utcDateStart: ' + utcDateStart)

      setIsEventRunning(isEventRunning)
    } else if (isLiveEvent) {
      setIsEventRunning(isLiveEvent)
    }

    setIsLiveEvent(isLiveEvent)
  }, [bannerEvent])

  useEffect(() => {
    if (isStartedEvent) {
      refetch()
      setTimeout(() => {
        setIsStartedEvent(false)
        setIsEventRunning(true)
      }, 1000)

      setTimeout(() => {
        setIsOverlayOpen(false)
      }, 10000)
    }
  }, [isStartedEvent])

  useEffect(() => {
    if (isCloseOverlay) {
      setIsOverlayOpen(false)
    }
  }, [isCloseOverlay])

  useEffect(() => {
    const header = document.getElementById('header-height')
    const iframe = document.getElementById('iframe')
    const homepage = document.getElementById('homepage-container')
    if (iframe && homepage) {
      if (!(width / height < 1.56)) {
        iframe.style.height = `calc(100vh - ${header?.offsetHeight}px)`
        homepage.style.height = `calc(100vh - ${header?.offsetHeight}px)`
      } else {
        iframe.style.height = '100%'
        homepage.style.height = '100%'
      }
    }
  })

  useEffect(() => {
    if (isLiveEvent) {
      setTimeout(() => {
        setIsOverlayOpen(false)
      }, 10000)
    }
  }, [isLiveEvent])

  const renderBanner = () => {
    if ((isStartedEvent || isLiveEvent) && isEventRunning) {
      if (bannerEvent[0]?.stream?.hls_url) {
        return <ReactPlayer
                    url={bannerEvent[0]?.stream?.hls_url}
                    controls={true}
                    width="100%"
                    height="auto"
                    style={{
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                      overflow: 'hidden'
                    }}
                    playing={false}
                />
      } else if (bannerEvent[0]?.stream?.embed_code) {
        return <div className='iframe-container' style={{
          overflow: 'hidden'
        }}>
                    <iframe
                        src={bannerEvent[0]?.stream?.embed_code}
                        allowFullScreen
                        id='iframe'
                        style={{ height: '90vh !important' }}
                        allow="autoplay; fullscreen; picture-in-picture; controls"
                    ></iframe>
                </div>
      } else if (bannerEvent[0]?.image?.download_url) {
        return <img
                    className="banner-img"
                    width="100%"
                    style={{ height: '91vh' }}
                    src={bannerEvent[0]?.image?.download_url}
                    alt="event-card"
                />
      } else {
        return <img src={bg}
                            className="banner-img"
                            width="100%"
                            style={{ height: '91vh' }}
                            alt="event-card"/>
      }
    } else {
      if (bannerEvent[0]?.image?.download_url) {
        return <img
                    className="banner-img"
                    width="100%"
                    style={{ height: '91vh' }}
                    src={bannerEvent[0]?.image?.download_url}
                    alt="event-card"
                />
      } else if (bannerEvent[0]?.stream?.hls_url) {
        return <ReactPlayer
                    url={bannerEvent[0]?.stream?.hls_url}
                    controls={true}
                    width="100%"
                    height="auto"
                    style={{
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                      overflow: 'hidden'
                    }}
                    playing={false}
                />
      } else if (bannerEvent[0]?.stream?.embed_code) {
        return <div className='iframe-container' style={{
          overflow: 'hidden'
        }}>
                    <iframe
                        src={bannerEvent[0]?.stream?.embed_code}
                        allowFullScreen
                        id='iframe'
                        style={{ height: '90vh !important' }}
                        allow="autoplay; fullscreen; picture-in-picture; controls"
                    ></iframe>
                </div>
      } else {
        return <img src={bg}
                            className="banner-img"
                            width="100%"
                            style={{ height: '91vh' }}
                            alt="event-card"/>
      }
    }
  }

  return (
        <>
            {!!bannerEvent?.length && (
                <div
                    onClick={() => {
                      navigate(
                        '/events/' +
                            bannerEvent[0]?.id +
                            '/' +
                            bannerEvent[0]?.slug,
                        {
                          state: { id: bannerEvent[0]?.id }
                        }
                      )
                    }}
                    className="homepage-container"
                    id="homepage-container"
                >
                    {/* {isStartedEvent || isLiveEvent && !bannerEvent[0]?.schedule_end && */}
                    {/*    <div className='live-btn'><LivebtnIcon/></div>} */}
                    {renderBanner()}
                    <div className="banner-play-btn">
                        {/* <PlayBtn/> */}
                        {/* <p className="black-rounded-btn-entry"><FormattedMessage id="click-here-to-enter" defaultMessage="Click here to enter"/></p> */}
                    </div>
                    {isOverlayOpen && <div className="details-container">
                        <div className='details-container__close-icon' onClick={(e) => {
                          e.stopPropagation()
                          setIsOverlayOpen(false)
                        }}>
                            {isLiveEvent && <CloseIcon color='#FFF'/>}
                        </div>
                        <div className="homepage-section-2-text">
                            {isEventRunning
                              ? (
                                    <button className="banner-live-now-btn">
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="4" cy="4" r="4" fill="white"/>
                                        </svg>
                                        <FormattedMessage id="live-now-uppercase" defaultMessage="LIVE NOW"/>
                                    </button>
                                )
                              : ''}
                            <p className='time'>{formatStreamedDate(bannerEvent[0]?.schedule_begin)}</p>
                            <p className="heading">{bannerEvent[0]?.title}</p>
                            <p className="para">{bannerEvent[0]?.hero_text}</p>
                        </div>
                    </div>}
                </div>
            )}
        </>
  )
}
export default Banner
